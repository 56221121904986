import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredRelease: [],
};

const searchRelease = createSlice({
  name: "searchRelease",
  initialState,
  reducers: {
    setFilteredRelease: (state, action) => {
      state.filteredRelease = [...action.payload];
    },
  },
});

export const { setFilteredRelease } = searchRelease.actions;
export default searchRelease.reducer;
