import {
  ArrowUpOnSquareIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PencilSquareIcon,
  PlayCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Header";
import DeleteAssets from "./DeleteAssets";
import { Dialog, Transition } from "@headlessui/react";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Loading";
import { setOpenModal } from "../../stores/modal";
import { getAsset } from "../../utils/lib/get-asset";
import { useCookies } from "react-cookie";
import { getSubgenre } from "../../utils/lib/get-subgenre";
import { getArtists } from "../../utils/lib/get-artists";
import { getGenres } from "../../utils/lib/get-genres";
import { getPublishers } from "../../utils/lib/get-publishers";
import { getPeople } from "../../utils/lib/get-people";
import CreatePeople from "../people/CreatePeople";

const initialFormModel = {
  title: "",
  displayArtists: "",
  trackVersion: "",
  isrc: "",
  recordingYear: "",
  p_CopyrightYear: "",
  p_CopyrightLine: "",
  genre: "",
  genreId: "",
  subgenre: "",
  subgenreId: "",
  alternateGenreId: "",
  alternateSubgenreId: "",
  lyrics: "",
  previewStart: "",
  previewLength: "",
  language: "",
  explicitness: "",
  audioLanguage: "",
  recordingLocation: "",
  rightsHolderName: "",
  countryOfRecording: "",
  countryOfCommissioning: "",
  rightsOwnershipName: "",
  rightsContractBeginDate: "",
  assetArtists: [],
  translations: [],
};

export default function AssetDetail() {
  const { id } = useParams();
  const { credential, partnerIds } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { openModal } = useSelector(state => state.modal);

  //asset info
  const [asset, setAsset] = useState(initialFormModel);
  const [title, setTitle] = useState("");
  const [displayArtists, setDisplayArtists] = useState([]);
  const [trackVersion, setTrackVersion] = useState("");
  const [isrc, setIsrc] = useState("");
  const [recordingYear, setRecordingYear] = useState("");
  const [p_CopyrightYear, setP_CopyrightYear] = useState("");
  const [p_CopyrightLine, setP_CopyrightLine] = useState("");
  const [genre, setGenre] = useState("");
  const [genreId, setGenreId] = useState("");
  const [subgenre, setSubgenre] = useState("");
  const [subgenreId, setSubGenreId] = useState("");
  const [alternateGenreId, setAlternateGenreId] = useState("");
  const [alternateSubgenreId, setAlternateSubgenreId] = useState("");
  const [lyrics, setLyrics] = useState("");
  const [previewStart, setPreviewStart] = useState("");
  const [previewLength, setPreviewLength] = useState("");
  const [language, setLanguage] = useState("");
  const [explicitness, setExplicitness] = useState("");
  const [audioLanguage, setAudioLanguage] = useState("");
  const [recordingLocation, setRecordingLocation] = useState("");
  const [rightsHolderName, setRightHolderName] = useState("");
  const [countryOfRecording, setCountryOfRecording] = useState("");
  const [countryOfCommissioning, setCountryOfComissioning] = useState("");
  const [rightsOwnershipName, setRightsOwnershipName] = useState("");
  const [rightsContractBeginDate, setRightsContractBeginDate] = useState("");
  const [lyricsModal, setLyricsModal] = useState(false);
  const [alternateGenre, setAlternateGenre] = useState("");
  const [alternateSubgenre, setAlternateSubgenre] = useState("");
  const [artistToAsset, setArtistToAsset] = useState("");
  const [translations, setTranslations] = useState("");
  const [publisherToAsset, setPublisherToAsset] = useState("");
  const [metadataLanguageModal, setMetadataLanguageModal] = useState(false);
  const [audioLanguageModal, setAudioLanguageModal] = useState(false);
  const [selectedMetadataLanguage, setSelectedMetadataLanguage] = useState("");
  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState("");
  const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

  const headerSet = {
    Authorization: `Bearer ${credential}`,
    // PartnerId: getPartnerIds(partnerIds),
  };

  // Fetch asset details
  useEffect(() => {
    fetchAsset();
  }, []);

  const fetchAsset = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const asset = await getAsset(headers, id);
      setAsset(asset);
      setLoading(false);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  const assetTitle = asset.title;
  const assetDisplayArtists = asset.displayArtists;
  const assetTrackVersion = asset.trackVersion;
  const assetIsrc = asset.isrc;
  const assetRecordingYear = asset.recordingYear;
  const assetP_CopyrightYear = asset.p_CopyrightYear;
  const assetP_CopyRightLine = asset.p_CopyrightLine;
  const assetGenre = asset.genre?.name;
  const assetGenreId = asset.genreId;
  const assetSubgenre = asset.subgenre?.name;
  const assetSubgenreId = asset.subgenreId;
  const assetAlternateGenreId = asset.alternateGenreId;
  const assetAlternateGenre = asset.alternateGenre?.name;
  const assetAlternateSubgenre = asset.alternateSubgenre?.name;
  const assetAlternateSubgenreId = asset.alternateSubgenreId;
  const assetLyrics = asset.lyrics;
  const assetPreviewStart = asset.previewStart;
  const assetPreviewLength = asset.previewLength;
  const assetLanguage = asset.language;
  const assetExplicitness = asset.explicitness;
  const assetAudioLanguage = asset.audioLanguage;
  const assetRecordingLocation = asset.recordingLocation;
  const assetRightsHolderName = asset.rightsHolderName;
  const assetCountryOfRecording = asset.countryOfRecording;
  const assetCountryOfCommissioning = asset.countryOfCommissioning;
  const assetRightsOwnershipName = asset.rightsOwnershipName;
  const assetRightsContractBeginDate = asset.rightsContractBeginDate;
  const assetArtists = asset.assetArtists;
  const assetTranslations = asset.translations;
  const assetContributors = asset.assetPeople;
  const assetPublishers = asset.assetPublishers;

  useEffect(() => {
    if (!assetTitle) {
      return;
    }
    setTitle(assetTitle);
    setDisplayArtists(assetDisplayArtists);
    setTrackVersion(assetTrackVersion);
    setIsrc(assetIsrc);
    setRecordingYear(assetRecordingYear);
    setP_CopyrightYear(assetP_CopyrightYear);
    setP_CopyrightLine(assetP_CopyRightLine);
    setGenre(assetGenre);
    setGenreId(assetGenreId);
    setSubgenre(assetSubgenre);
    setSubGenreId(assetSubgenreId);
    setAlternateGenreId(assetAlternateGenreId);
    setAlternateSubgenreId(assetAlternateSubgenreId);
    setLyrics(assetLyrics);
    setPreviewStart(assetPreviewStart);
    setPreviewLength(assetPreviewLength);
    setLanguage(assetLanguage);
    setExplicitness(assetExplicitness);
    setAudioLanguage(assetAudioLanguage);
    setRecordingLocation(assetRecordingLocation);
    setRightHolderName(assetRightsHolderName);
    setCountryOfRecording(assetCountryOfRecording);
    setCountryOfComissioning(assetCountryOfCommissioning);
    setRightsOwnershipName(assetRightsOwnershipName);
    setRightsContractBeginDate(assetRightsContractBeginDate);
    setAlternateGenre(assetAlternateGenre);
    setAlternateSubgenre(assetAlternateSubgenre);
    setArtistToAsset(assetArtists);
    setTranslations(assetTranslations);
    setPublisherToAsset(assetPublishers);
  }, [
    assetTitle,
    assetDisplayArtists,
    assetTrackVersion,
    assetIsrc,
    assetRecordingYear,
    assetP_CopyrightYear,
    assetP_CopyRightLine,
    assetGenre,
    assetGenreId,
    assetSubgenre,
    assetSubgenreId,
    assetAlternateGenreId,
    assetAlternateSubgenreId,
    assetLyrics,
    assetPreviewStart,
    assetPreviewLength,
    assetLanguage,
    assetExplicitness,
    assetAudioLanguage,
    assetRecordingLocation,
    assetRightsHolderName,
    assetCountryOfRecording,
    assetCountryOfCommissioning,
    assetRightsOwnershipName,
    assetRightsContractBeginDate,
    assetAlternateGenre,
    assetAlternateSubgenre,
    assetArtists,
    assetTranslations,
    assetPublishers,
  ]);

  const onChange = (evt) => {
    const { name, value } = evt.target;
    const newAsset = { ...asset, [name]: value };
    setAsset(newAsset);
    debouncedUpdate(newAsset);
  };

  const onSubmitClick = () => {
    debouncedUpdate(asset);
  };

  //Other
  const debouncedUpdate = useCallback(
    debounce(async (asset) => {
      const headers = {
        ...headerSet,
        partnerId: cookie.selectedPartnerId,
      };
      try {
        const response = await axios.put(
          `https://ddex-384918.ew.r.appspot.com/asset/${id}`,
          {
            title: asset.title,
            recordingYear: asset.recordingYear,
            recordingLocation: asset.recordingLocation,
            rightsHolderName: asset.rightsHolderName,
            countryOfRecording: asset.countryOfRecording,
            countryOfCommissioning: asset.countryOfCommissioning,
            rightsOwnershipName: asset.rightsOwnershipName,
            rightsContractBeginDate: asset.rightsContractBeginDate,
            isrc: asset.isrc,
            trackVersion: asset.trackVersion,
            displayArtists: asset.displayArtists,
            p_CopyrightYear: asset.p_CopyrightYear,
            p_CopyrightLine: asset.p_CopyrightLine,
            lyrics: asset.lyrics,
            genreId: asset.genreId,
            subgenreId: asset.subgenreId,
            alternateGenreId: asset.alternateGenreId,
            alternateSubgenreId: asset.alternateSubgenreId,
            language: asset.language,
            audioLanguage: asset.audioLanguage,
          },
          { headers }
        );
        toast.success("Asset updated successfully");
      } catch (error) {
        const errorMessage = error.response.data.title;
        toast.error(`Error: ${errorMessage}`);
      }
    }, 1000),
    []
  );

  const formatDate = (date) => {
    let d = new Date(date);

    // Tarih kontrolü
    if (isNaN(d.getTime())) {
      console.error(`Invalid date value: ${date}`);
      return date; // Mevcut değer
    }

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onRightsContractBeginDateChange = (date) => {
    let formattedDate = formatDate(date);
    setRightsContractBeginDate(formattedDate);
    debouncedUpdate({ ...asset, rightsContractBeginDate: formattedDate });
  };
  const handleLyricsModal = () => {
    setLyricsModal(!lyricsModal);
  };

  //Upload Audio File
  const [createAudio, setCreateAudio] = useState();
  const [audioFile, setAudioFile] = useState();
  const [audioLoading, setAudioLoading] = useState(false);

  const onAudioFileChange = async (event) => {
    event.persist();
    const file = event.target.files[0];
    setAudioFile(file);
    await UploadAudioFile(file);
  };

  // File yükleme işlevi
  const UploadAudioFile = async (file) => {
    setAudioLoading(true);
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    const formData = new FormData();
    formData.append("audioFile", file);
    try {
      const response = await axios.post(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/uploadAudioFile`,
        formData,
        { headers: { ...headers, "Content-Type": "multipart/form-data" } }
      );
      const uploadedAudioFile = response.data;
      setCreateAudio(uploadedAudioFile);
      toast.success("Uploaded audio file, please refresh the page");
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    } finally {
      setAudioLoading(false);
    }
  };

  // artist
  const [artistList, setArtistList] = useState();
  const [addArtistDropdownModal, setAddArtistDropdownModal] = useState(false);
  const [searchArtistQuery, setSearchArtistQuery] = useState("");
  const [filteredArtist, setFilteredArtist] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState();
  const [addedArtist, setAddedArtist] = useState();
  const [artistToProduct, setArtistToProduct] = useState([]);
  const [artistId, setArtistId] = useState("");
  const [artistRole, setArtistRole] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [artistsForProducts, setArtistsForProducts] = useState([]);

  const handleAddArtistDropdownModal = () => {
    setAddArtistDropdownModal(false);
    setSearchArtistQuery("");
  };

  useEffect(() => {
    if (addArtistDropdownModal) {
      fetchArtistData();
    }
  }, [addArtistDropdownModal]);

  const handleArtistSearch = (event) => {
    const { value } = event.target;
    setSearchArtistQuery(value);
  };

  const handleSelectedArtist = (id) => {
    setSelectedArtist(id);
    addArtistToProduct(id);
  };

  //get artists
  const fetchArtistData = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const artists = await getArtists(headers);
      setArtistList(artists);
      setLoading(false);
      const artistsRole = artists.map((artist) => ({
        ...artist,
        isPrimary: artist.artistRole === "Primary",
      }));
      setArtistsForProducts(artistsRole);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (!artistList) return;

    if (!Array.isArray(artistList)) {
      console.error("artistList dizi olmalı:", artistList);
      return;
    }
    const addedArtistNames = artistList.map((artist) => {
      return {
        id: artist.id,
        name: artist.name.toLowerCase(),
      };
    });

    const searchFilteredArtists = addedArtistNames.filter((artist) =>
      artist.name.includes(searchArtistQuery.toLowerCase())
    );

    setFilteredArtist(searchFilteredArtists);
  }, [searchArtistQuery, artistList]);

  //add artists request
  const addArtistToProduct = async (selectedArtist) => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.post(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/artistRelation`,
        {
          artistId: selectedArtist,
        },
        {
          headers: headers,
        }
      );
      const addedArtist = response.data;
      setAddedArtist(addedArtist);
      toast.success("Added artist successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  // delete artist from products
  const artistToBeDeleted = (artistId) => {
    setArtistId(artistId);
    deleteArtistFromProduct(artistId);
  };

  const deleteArtistFromProduct = async (artistId) => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };

    try {
      // DELETE
      const response = await axios.delete(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/artistRelation/${artistId}`,
        { headers }
      );
      const deletedProduct = response.data;
      setArtistToProduct(deletedProduct);
      toast.success("Artist deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Asset silinirken bir hata oluştu:", error);
    }
  };

  // change artistRole
  const handleRoleChange = (artistId, currentRole) => {
    const newRole = currentRole === "Primary" ? "Featuring" : "Primary";
    setArtistsForProducts(
      assetArtists.map((artist) =>
        artist.id === artistId ? { ...artist, artistRole: newRole } : artist
      )
    );
    changeArtistRole(artistId, newRole);
  };

  const changeArtistRole = (artistId, newRole) => {
    setArtistId(artistId);
    changeArtistRoleRequest(artistId, newRole);
  };

  //add artists request
  const changeArtistRoleRequest = async (artistId, newRole) => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.put(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/artistRelation/${artistId}`,
        {
          artistRole: newRole,
        },
        {
          headers: headers,
        }
      );
      const updatedArtistRole = response.data;
      setArtistRole(updatedArtistRole);
      toast.success("Updated artist role successfully");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  //Translations
  const [addTranslation, setAddTranslation] = useState(false);
  const [languageNames, setLanguageNames] = useState([]);
  const [filteredLanguage, setFilteredLanguage] = useState();
  const [searchQueryTranslation, setSearchQueryTranslation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [editTranslation, setEditTranslation] = useState(false);
  const [editingArtistName, setEditingArtistName] = useState(null);
  const [editedArtistName, setEditedArtistName] = useState();
  const [editAddedTranslation, setEditAddedTranslation] = useState(false);
  const [editingTranslationId, setEditingTranslationId] = useState("");
  const [closeDeleteModal, setCloseDeleteModal] = useState(false);
  const [deleteModalTranslation, setDeleteModalTranslation] = useState(false);
  const [deletingTranslationId, setDeletingTranslationId] = useState("");
  const [handleModalT, setHandleModalT] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  // Contributors
  const [addContributor, setAddContributor] = useState(false);
  const [contributorList, setContributorList] = useState();
  const [filteredContributor, setFilteredContributor] = useState([]);
  const [selectedContributor, setSelectedContributor] = useState();
  const [selectedContributorId, setSelectedContributorId] = useState();
  const [openContributorModal, setOpenContributorModal] = useState(false);
  const [editContributorModal, setEditContributorModal] = useState(false);

  const [editedContributorRole, setEditedContributorRole] = useState();
  const [editedContributorInstrument, setEditedContributorInstrument] = useState();

  const [editingContributorRole, setEditingContributorRole] = useState();
  const [editingContributorInstrument, setEditingContributorInstrument] = useState();
  const [editingContributorId, setEditingContributorId] = useState();
  const [editingContributorName, setEditingContributorName] = useState();

  const [deleteModalContributor, setDeleteModalContributor] = useState(false);
  const [deletingContributorId, setDeletingContributorId] = useState("");


  const [searchQueryContributor, setSearchQueryContributor] = useState("");

  const [addRole, setAddRole] = useState(false);
  const [roles, setRoles] = useState([]);
  const [editRole, setEditRole] = useState(false);
  const [editInstrument, setEditInstrument] = useState(false);

  const [addInstrument, setAddInstrument] = useState(false);
  const [instruments, setInstruments] = useState([]);

  const handleContributorSearch = (event) => {
    const { value } = event.target;
    setSearchQueryContributor(value);
  };


  const closeAddContributorModal = () => {
    setAddContributor(false);
    setSearchQueryContributor("");
  };

  const handleTranslationSearch = (event) => {
    const { value } = event.target;
    setSearchQueryTranslation(value);
  };


  //get contributors
  const fetchContributorData = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const people = await getPeople(headers);
      setContributorList(people);
      setLoading(false);
    } catch (error) {
      toast.error("Error:" + error.response.data.message);
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  //edited added contributor data
  const editedAddedContributorData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      await axios.put(
          `https://ddex-384918.ew.r.appspot.com/asset/${id}/personRelation/${editingContributorId}`,
          {
            role: editingContributorRole,
            instrument: editingContributorInstrument,
          },
          {
            headers: headers,
          }
      );
      toast.success("Contributor has been edited successfully");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Error:" + error.response.data.message);
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  //delete added contributor data
  const deleteContributorData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      await axios.delete(
          `https://ddex-384918.ew.r.appspot.com/asset/${id}/personRelation/${deletingContributorId}`,
          {
            headers: headers,
          }
      );
      setLoading(false);
      toast.success("Contributor has been deleted successfully");
      window.location.reload();
    } catch (error) {
      toast.error("Error:" + error.response.data.message);
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (addContributor) {
      fetchContributorData();
    }
  }, [addContributor]);

  useEffect(() => {
    const fetchRoles = async () => {
      const headers = {
        ...headerSet,
      };
      try {
        const response = await axios.get(
            `https://ddex-384918.ew.r.appspot.com/role`,
            { headers }
        );
        const roles = response.data;
        setRoles(roles);

        setLoading(false);
      } catch (error) {
        toast.error("Error:" + error.response.data.message);
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };
    if (addRole || editRole) {
      fetchRoles();
    }
  }, [addRole, editRole]);

  useEffect(() => {
    const fetchInstruments = async () => {
      const headers = {
        ...headerSet,
      };
      try {
        const response = await axios.get(
            `https://ddex-384918.ew.r.appspot.com/instrument`,
            { headers }
        );
        const instruments = response.data;
        setInstruments(instruments);
        setLoading(false);
      } catch (error) {
        toast.error("Error:" + error.response.data.message);
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };

    if (addInstrument || editInstrument) {
      fetchInstruments();
    }
  }, [addInstrument, editInstrument]);

  useEffect(() => {
    if (!contributorList) return;

    if (!Array.isArray(contributorList)) {
      console.error("contributorList dizi olmalı:", contributorList);
      return;
    }

    const addedContributorNames = contributorList.map((contributor) => {
      return {
        id: contributor.id,
        name: contributor.name.toLowerCase(),
      };
    });

    const searchFilteredContributors = addedContributorNames.filter((contributor) =>
        contributor.name.includes(searchQueryContributor.toLowerCase())
    );

    setFilteredContributor(searchFilteredContributors);
  }, [searchQueryContributor, contributorList]);

  const handleSelectedContributor = (contributor) => {
    setAddContributor(false);
    setSelectedContributor(contributor.name);
    setSelectedContributorId(contributor.id);
    setOpenContributorModal(true);
  };

  const closeModalContributor = () => {
    setOpenContributorModal(false);
    setHandleModalT(false);
    setEditingArtistName("");
  };

  const createContributorData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      await axios.post(
          `https://ddex-384918.ew.r.appspot.com/asset/${id}/personRelation`,
          {
            personId: selectedContributorId,
            role: editedContributorRole,
            instrument: editedContributorInstrument,
          },
          {
            headers: headers,
          }
      );
      toast.success("Contributor has been added successfully");
      window.location.reload();
    } catch (error) {
      toast.error("Error:" + error.response.data.message);
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  function selectContributorForEditing(id, name, role, instrument) {
    setEditContributorModal(!editContributorModal);
    setEditingContributorId(id);
    setEditingContributorName(name);
    setEditingContributorRole(role);
    setEditingContributorInstrument(instrument);
  }

  const handleDeleteModalContributor = (id) => {
    setDeleteModalContributor(!deleteModalContributor);
    setDeletingContributorId(id);
  };

  const closeModalDeleteContributor = () => {
    setCloseDeleteModal(false);
    setDeleteModalContributor(false);
  };

  //////////////

  const handleSelectedLanguage = (language) => {
    setSelectedLanguage(language);
    setOpenTranslationModal(true);
  };

  const closeTranslationModal = () => {
    setAddTranslation(false);
    setSearchQueryTranslation("");
    setEditTranslation(false);
  };

  const closeModalT = () => {
    setOpenTranslationModal(false);
    setHandleModalT(false);
    setEditTranslation(false);
    setEditingArtistName("");
  };

  const handleChangeTranslation = (e) => {
    const { value } = e.target;
    setEditedArtistName(value);
  };

  //post translation data
  const createTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/translation`,
        {
          language: selectedLanguage,
          title: editedArtistName,
        },
        {
          headers: headers,
        }
      );
      const createdTranslation = response.data;
      setTranslations(createdTranslation);
      dispatch(setOpenModal(false));
      closeModalT();
      closeTranslationModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  const openEditTranslationModal = () => {
    setEditTranslation(true);
  };

  function selectArtistNameForEditing(artistName, translationId) {
    setEditAddedTranslation(!editAddedTranslation);
    setEditingArtistName(artistName);
    setEditingTranslationId(translationId);
  }

  //edited added translation data
  const editedAddedTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.put(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/translation/${editingTranslationId}`,
        {
          title: editingArtistName,
        },
        {
          headers: headers,
        }
      );
      const updatedTranslationName = response.data;
      setTranslations(updatedTranslationName);
      setLoading(false);
      setEditAddedTranslation(false);
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  const closeModalDeleteTranslation = () => {
    setCloseDeleteModal(false);
    setEditTranslation(false);
    setEditedArtistName("");
  };

  //delete added translation data
  const deleteAddedTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.delete(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/translation/${deletingTranslationId}`,
        {
          headers: headers,
        }
      );
      const deleteTranslationData = response.data;
      setTranslations(deleteTranslationData);
      setLoading(false);
      handleDeleteModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  const handleDeleteModal = (identifierId) => {
    setDeleteModal(!deleteModal);
  };

  const handleDeleteModalTranslation = (translationId) => {
    setDeleteModalTranslation(!deleteModalTranslation);
    setDeletingTranslationId(translationId);
  };

  useEffect(() => {
    const fetchLang = async () => {
      const headers = {
        ...headerSet,
      };
      try {
        const response = await axios.get(
          `https://ddex-384918.ew.r.appspot.com/language`,
          { headers }
        );
        const languages = response.data;
        setLanguageNames(languages);

        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };

    fetchLang();
  }, [addTranslation]);

  useEffect(() => {
    if (!assetTranslations) return;

    if (!Array.isArray(assetTranslations)) {
      console.error("assetTranslations dizi olmalı:", assetTranslations);
      return;
    }

    const addedLanguageNames = assetTranslations.map((translation) =>
      translation.language.toLowerCase()
    );

    const searchFilteredLanguages = languageNames.filter(
      (lang) =>
        lang.toLowerCase().includes(searchQueryTranslation.toLowerCase()) &&
        !addedLanguageNames.includes(lang.toLowerCase())
    );

    setFilteredLanguage(searchFilteredLanguages);
  }, [searchQueryTranslation, assetTranslations]);

  //genre
  const [allGenre, setAllGenre] = useState([]);
  const [genreModal, setGenreModal] = useState(false);
  const [filteredGenre, setFilteredGenre] = useState([]);
  const [searchGenreQuery, setSearchGenreQuery] = useState("");
  const [selectedGenre, setSelectedGenre] = useState();
  const [selectedGenreName, setSelectedGenreName] = useState("");
  const [selectedAlternateGenreName, setSelectedAlternateGenreName] =
    useState("");
  const [alternateGenreModal, setAlternateGenreModal] = useState(false);
  const [searchAlternateGenreQuery, setSearchAlternateGenreQuery] =
    useState("");
  const [filteredAlternateGenre, setFilteredAlternateGenre] = useState([]);
  const [selectedAlternateGenre, setSelectedAlternateGenre] = useState();

  const fetchGenreData = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await getGenres(headers);
      const genres = response.data;
      setAllGenre(genres);
      setLoading(false);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchGenreData();
  }, []);

  const handleGenreSearch = (event) => {
    const { value } = event.target;
    setSearchGenreQuery(value);
  };

  useEffect(() => {
    if (!allGenre) return;

    if (!Array.isArray(allGenre)) {
      console.error("allGenre dizi olmalı:", allGenre);
      return;
    }
    const addedGenreName = allGenre.map((genre) => {
      return {
        id: genre.id,
        name: genre.name.toLowerCase(),
      };
    });

    const searchFilteredGenre = addedGenreName.filter((genre) =>
      genre.name.includes(searchGenreQuery.toLowerCase())
    );

    setFilteredGenre(searchFilteredGenre);
    setFilteredAlternateGenre(searchFilteredGenre);
  }, [searchGenreQuery, allGenre]);

  const handleSelectedGenre = (id, name) => {
    setSelectedGenre(id);
    setSelectedGenreName(name);
    setGenreModal(false);
    debouncedUpdate({ ...asset, genreId: id });
  };

  //subgenre
  const [allSubgenre, setAllSubgenre] = useState([]);
  const [subgenreModal, setSubgenreModal] = useState(false);
  const [searchSubgenreQuery, setSearchSubgenreQuery] = useState("");
  const [filteredSubgenre, setFilteredSubgenre] = useState([]);
  const [selectedSubgenre, setSelectedSubgenre] = useState();
  const [selectedSubgenreName, setSelectedSubgenreName] = useState("");
  const [deleteSubgenreId, setDeleteSubgenreId] = useState("");
  const [newSubgenre, setNewSubgenre] = useState("");
  const [selectedAlternateSubgenre, setSelectedAlternateSubgenre] =
    useState("");
  const [alternateSubgenreModal, setAlternateSubgenreModal] = useState(false);
  const [newAlternateSubgenre, setNewAlternateSubgenre] = useState("");
  const [filteredAlternateSubgenre, setFilteredAlternateSubgenre] = useState(
    []
  );
  const [selectedAlternateSubgenreName, setSelectedAlternateSubgenreName] =
    useState("");
  const [deleteAlternateSubgenreId, setDeleteAlternateSubgenreId] =
    useState("");

  const fetchSubgenreData = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const subgenres = await getSubgenre(headers);
      setAllSubgenre(subgenres);
      setLoading(false);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchSubgenreData();
  }, []);

  const handleSubgenreSearch = (event) => {
    const { value } = event.target;
    setSearchSubgenreQuery(value);
  };

  useEffect(() => {
    if (!allSubgenre) return;

    if (!Array.isArray(allSubgenre)) {
      console.error("allSubgenre dizi olmalı:", allSubgenre);
      return;
    }
    const addedSubgenreName = allSubgenre.map((subgenre) => {
      return {
        id: subgenre.id,
        name: subgenre.name.toLowerCase(),
      };
    });

    const searchFilteredSubgenre = addedSubgenreName.filter((subgenre) =>
      subgenre.name.includes(searchSubgenreQuery.toLowerCase())
    );
    setFilteredSubgenre(searchFilteredSubgenre);
    setFilteredAlternateSubgenre(searchFilteredSubgenre);
  }, [searchSubgenreQuery, allSubgenre]);

  const handleSelectedSubgenre = (id, name) => {
    setSelectedSubgenre(id);
    setSelectedSubgenreName(name);
    setSubgenreModal(false);
    debouncedUpdate({ ...asset, subgenreId: id });
  };

  const deleteSubgenre = (id) => {
    setDeleteSubgenreId(id);
    deleteSubgenreFromProduct(id);
  };

  // delete subgenre
  const deleteSubgenreFromProduct = async (deletedId) => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      // DELETE
      const response = await axios.delete(
        `https://ddex-384918.ew.r.appspot.com/subgenre/${deletedId}`,
        { headers }
      );
      const deletedSubgenre = response.data;
      setAllSubgenre(deletedSubgenre);
      toast.success("Subgenre deleted successfully");
    } catch (error) {
      let errorMessage = error.response.data.message;
      toast.error("Error:" + errorMessage);
    }
  };

  const addNewSubgenre = (e) => {
    setNewSubgenre(e.target.value);
  };

  const addNewSubgenreRequest = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
        `https://ddex-384918.ew.r.appspot.com/subgenre`,
        {
          name: newSubgenre,
        },
        {
          headers: headers,
        }
      );
      const addedSubgenre = response.data;
      setAllSubgenre(addedSubgenre);
      toast.success("Added subgenre successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error("API isteğinde hata meydana geldi.", error.message);
    }
  };

  //alternate genre
  const handleAlternateGenreSearch = (event) => {
    const { value } = event.target;
    setSearchAlternateGenreQuery(value);
  };

  const handleSelectedAlternateGenre = (id, name) => {
    setSelectedAlternateGenre(id);
    setSelectedAlternateGenreName(name);
    setAlternateGenreModal(false);
    debouncedUpdate({ ...asset, alternateGenreId: id });
  };

  // alternate subgenre
  const addNewAlternateSubgenre = (e) => {
    setNewAlternateSubgenre(e.target.value);
  };

  const handleSelectedAlternateSubgenre = (id, name) => {
    setSelectedAlternateSubgenre(id);
    setSelectedAlternateSubgenreName(name);
    setAlternateSubgenreModal(false);
    debouncedUpdate({ ...asset, alternateSubgenreId: id });
  };

  // delete alternate subgenre
  const deleteAlternateSubgenreFromProduct = async (deletedId) => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };

    try {
      // DELETE
      const response = await axios.delete(
        `https://ddex-384918.ew.r.appspot.com/subgenre/${deletedId}`,
        { headers }
      );
      const deletedSubgenre = response.data;
      setAllSubgenre(deletedSubgenre);
      toast.success("Alternate Subgenre deleted successfully");
    } catch (error) {
      let errorMessage = error.response.data.message;
      toast.error("Error:" + errorMessage);
    }
  };

  const deleteAlternateSubgenre = (id) => {
    setDeleteAlternateSubgenreId(id);
    deleteAlternateSubgenreFromProduct(id);
  };

  //publishers
  const [publisherList, setPublisherList] = useState();
  const [addPublisherDropdownModal, setAddPublisherDropdownModal] =
    useState(false);
  const [searchPublisherQuery, setSearchPublisherQuery] = useState("");
  const [filteredPublisher, setFilteredPublisher] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState();
  const [addedPublisher, setAddedPublisher] = useState();
  const [publisherId, setPublisherId] = useState("");
  const [publishersForAssets, setPublishersForAssets] = useState([]);

  const handleAddPublisherDropdownModal = () => {
    setAddPublisherDropdownModal(false);
    setSearchPublisherQuery("");
  };

  useEffect(() => {
    if (addPublisherDropdownModal) {
      fetchPublisherData();
    }
  }, [addPublisherDropdownModal]);

  const handlePublisherSearch = (event) => {
    const { value } = event.target;
    setSearchPublisherQuery(value);
  };

  const handleSelectedPublisher = (id) => {
    setSelectedPublisher(id);
    addPublisherToAsset(id);
  };

  //get artists
  const fetchPublisherData = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const publishers = await getPublishers(headers);
      setPublisherList(publishers);
      setLoading(false);
    } catch (error) {
      toast.error("API error:", error);
    }
  };

  useEffect(() => {
    if (!publisherList) return;

    if (!Array.isArray(publisherList)) {
      console.error("publisherList dizi olmalı:", publisherList);
      return;
    }
    const addedPublisherNames = publisherList.map((pb) => {
      return {
        id: pb.id,
        name: pb.name.toLowerCase(),
      };
    });

    const searchFilteredPublishers = addedPublisherNames.filter((pb) =>
      pb.name.includes(searchPublisherQuery.toLowerCase())
    );

    setFilteredPublisher(searchFilteredPublishers);
  }, [searchPublisherQuery, publisherList]);

  //add artists request
  const addPublisherToAsset = async (selectedPublisher) => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.post(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/publisherRelation`,
        {
          publisherId: selectedPublisher,
        },
        {
          headers: headers,
        }
      );
      const addedPublisher = response.data;
      setAddedPublisher(addedPublisher);
      toast.success("Added publisher successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API eroror:", error.message);
      }
    }
  };

  // delete artist from products
  const publisherToBeDeleted = (publisherId) => {
    setPublisherId(publisherId);
    deletePublisherFromAsset(publisherId);
  };

  const deletePublisherFromAsset = async (publisherId) => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      // DELETE
      const response = await axios.delete(
        `https://ddex-384918.ew.r.appspot.com/asset/${id}/publisherRelation/${publisherId}`,
        { headers }
      );
      const deletedPublisher = response.data;
      setPublisherToAsset(deletedPublisher);
      toast.success("Publisher deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  const handleSelectedMetadataLanguage = (name) => {
    setSelectedMetadataLanguage(name);
    setMetadataLanguageModal(false);
    setSearchQueryTranslation("");
    setSelectedLanguage(name);
    debouncedUpdate({ ...asset, language: name });
  };

  const handleSelectedAudioLanguage = (name) => {
    setSelectedAudioLanguage(name);
    setAudioLanguageModal(false);
    debouncedUpdate({ ...asset, audioLanguage: name });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <ToastContainer autoClose={3000} />
      <Header />
      <DeleteAssets />
      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        <div className="flex items-center gap-2 py-6">
          <div>
            <Link to="/catalog/products" className="text-[#555555] text-sm">
              Catalog
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]" />
          <div>
            <Link to="/catalog/assets" className="text-[#555555] text-sm">
              Assets
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]" />
          <div>
            <Link
              to={`/catalog/assets/${id}`}
              className="text-[#555555] text-sm"
            >
              {assetTitle}
            </Link>
          </div>
          <div className="flex ml-auto">
            <button
              onClick={onSubmitClick}
              className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Submit
            </button>
          </div>
        </div>

        <div className="bg-white border border-[#e2e2e2] py-6 px-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="flex flex-col gap-1">
                <span className="text-sm font-semibold">{assetTitle}</span>
              </div>
            </div>
            <div className="text-xs font-light text-[#BBBBBB] px-6">
              ISRC: <span className="pl-2 text-[#212121]">{assetIsrc}</span>
            </div>
          </div>
        </div>
        {/* ARTIST */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex  justify-between items-center gap-2  px-6 text-xs text-[#555555B3] uppercase cursor-pointer relative">
            <div>Artists</div>
            <div
              className="flex items-center gap-2 hover:bg-gray-100 py-1 px-2 rounded-primary"
              onClick={() => setAddArtistDropdownModal(true)}
            >
              <PlusIcon className="w-6 h-6 text-primary" />
              <span>Add artist</span>
            </div>
            {addArtistDropdownModal && (
              <div className="z-10 top-0  right-0 shadow-xl absolute w-[300px] shadow-gray-300 border bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                <div className="py-2 px-4">
                  <div className="flex flex-col">
                    <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                      <input
                        type="text"
                        onChange={handleArtistSearch}
                        placeholder="Type here to search for artists"
                        className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                      />
                    </div>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                      {filteredArtist.map((artist, index) => (
                        <div
                          onClick={() => handleSelectedArtist(artist.id)}
                          key={index}
                          className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                            index === 0 && "bg-gray-200"
                          }`}
                        >
                          <span className="capitalize">{artist.name}</span>
                        </div>
                      ))}
                      {filteredArtist.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                    </ul>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <div className="flex flex-row-reverse pt-2">
                      <button
                        onClick={handleAddArtistDropdownModal}
                        className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {artistToProduct && (
            <div className="py-4 px-6">
              <div
                className={
                  assetArtists.length > 0
                    ? "grid grid-cols-4 gap-2 items-center"
                    : "flex items-center justify-center"
                }
              >
                {assetArtists.length > 0 ? (
                  assetArtists.map((artistItem, index) => {
                    return (
                      <div key={index} className="border rounded-primary">
                        <div className="py-2 px-4">
                          <div className="flex items-center justify-between">
                            <div className="block">
                              <h1 className="text-primary text-xs font-bold">
                                {artistItem.artist.name}
                              </h1>
                              <div className="flex items-center gap-1 py-1">
                                <label className="relative inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={
                                      artistItem.artistRole === "Primary"
                                    }
                                    onChange={() =>
                                      handleRoleChange(
                                        artistItem.id,
                                        artistItem.artistRole
                                      )
                                    }
                                  />
                                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
                                  <span className="ml-3 text-xs text-gray-500">
                                    {artistItem.artistRole}
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div
                              onClick={() => artistToBeDeleted(artistItem.id)}
                              className="cursor-pointer hover:bg-gray-200 p-2 rounded-full"
                            >
                              <TrashIcon className="w-6 h-6 text-primary" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-gray-200">No artist data </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* PUBLISHERS */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex  justify-between items-center gap-2  px-6 text-xs text-[#555555B3] uppercase cursor-pointer relative">
            <div>Publishers</div>
            <div
              className="flex items-center gap-2 hover:bg-gray-100 py-1 px-2 rounded-primary"
              onClick={() => setAddPublisherDropdownModal(true)}
            >
              <PlusIcon className="w-6 h-6 text-primary" />
              <span>Add Publisher</span>
            </div>
            {addPublisherDropdownModal && (
              <div className="z-10 top-0  right-0 shadow-xl absolute w-[300px] shadow-gray-300 border bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                <div className="py-2 px-4">
                  <div className="flex flex-col">
                    <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                      <input
                        type="text"
                        onChange={handlePublisherSearch}
                        placeholder="Type here to search for artists"
                        className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                      />
                    </div>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                      {filteredPublisher.map((publisher, index) => (
                        <div
                          onClick={() => handleSelectedPublisher(publisher.id)}
                          key={index}
                          className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                            index === 0 && "bg-gray-200"
                          }`}
                        >
                          <span className="capitalize">{publisher.name}</span>
                        </div>
                      ))}
                      {filteredPublisher.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                    </ul>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <div className="flex flex-row-reverse pt-2">
                      <button
                        onClick={handleAddPublisherDropdownModal}
                        className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {publisherToAsset && (
            <div className="py-4 px-6">
              <div
                className={
                  assetPublishers.length > 0
                    ? "grid grid-cols-4 gap-2 items-center"
                    : "flex items-center justify-center"
                }
              >
                {assetPublishers.length > 0 ? (
                  assetPublishers.map((publisherItem, index) => {
                    return (
                      <div key={index} className="border rounded-primary">
                        <div className="py-2 px-4">
                          <div className="flex items-center justify-between">
                            <div className="block">
                              <h1 className="text-primary text-xs font-bold">
                                {publisherItem.publisher.name}
                              </h1>
                            </div>
                            <div
                              onClick={() =>
                                publisherToBeDeleted(publisherItem.id)
                              }
                              className="cursor-pointer hover:bg-gray-200 p-2 rounded-full"
                            >
                              <TrashIcon className="w-6 h-6 text-primary" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-gray-200">No publisher data </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* CONTRIBUTOR */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase mb-4">
            Contributors
          </div>

          <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Name
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
               Roles
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
               Instruments
              </span>
            </div>
            <div className="flex-[0_1_200px] mr-[10px] text-xs text-right relative">
              <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                <div
                    onClick={() => setAddContributor(true)}
                    className="flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer"
                >
                  <PlusIcon className="w-5 h-5 text-primary" />
                  <span className="text-right">Add Contributor</span>
                  <ChevronDownIcon className="w-5 h-5- text-primary" />
                </div>
                {addContributor && (
                    <div className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                      <div className="py-2 px-4">
                        <div className="flex flex-col">
                          <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                            <input
                                type="text"
                                onChange={handleContributorSearch}
                                placeholder="Type here to search for language"
                                className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                            />
                          </div>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                          <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                            {filteredContributor.map((contributor, index) => (
                                <div
                                    onClick={() => handleSelectedContributor(contributor)}
                                    key={index}
                                    className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                        index === 0 && "bg-gray-200"
                                    }`}
                                >
                                  <span className="capitalize">{contributor.name}</span>
                                </div>
                            ))}
                            {filteredContributor.length === 0 ? (
                                <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                                  Could not find anything
                                </div>
                            ) : null}
                          </ul>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                          <div
                              onClick={closeAddContributorModal}
                              className="sm:flex items-center justify-between sm:gap-3 p-4 pt-2"
                          >
                            <button type="button"
                                    className="uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            onClick={() => dispatch(setOpenModal(true))}>
                              Add New
                            </button>

                            <button
                                className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
          {assetContributors && assetContributors.length === 0 && (
              <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center">
                No items available
              </div>
          )}

          {Array.isArray(assetContributors) &&
              assetContributors.map((contributor, index) => {
                return (
                    <div
                        key={contributor.id}
                        className={
                          index % 2 === 0
                              ? "flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs"
                              : "bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs"
                        }
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                          {contributor.person.name}
                        </span>
                      </div>
                      <div className="flex-[1_1_200px] text-xs text-left">
                        <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                          {contributor.role}
                        </span>
                      </div>
                      <div className="flex-[1_1_200px] text-xs text-left">
                        <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                          {contributor.instrument}
                        </span>
                      </div>
                      <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                          <div className="flex items-center gap-6">
                            <div
                                onClick={() =>
                                    selectContributorForEditing(
                                        contributor.id,
                                        contributor.person.name,
                                        contributor.role,
                                        contributor.instrument
                                    )
                                }
                                className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                            >
                              <PencilIcon className="w-6 h-6 text-gray-500" />
                            </div>
                            <div
                                onClick={() =>
                                    handleDeleteModalContributor(contributor.id)
                                }
                                className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                            >
                              <TrashIcon className="w-6 h-6 text-gray-500" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })}
          {openModal && <CreatePeople />}

          {openContributorModal && (
              <>
                <Transition.Root show={openContributorModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeModalT}
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Add contributor</h1>
                                <p className="text-sm font-light mt-2 text-[#757575] flex">
                                  Select role and instrument to add contributor.
                                </p>
                              </div>
                              <div className="relative w-ful">
                                <div className="overflow-visible">
                                  <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                    <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Name
                                    </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        Role
                                      </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        Instrument
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="relative w-ful py-4">
                                <div className="overflow-visible">
                                  <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                    <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        {selectedContributor}
                                      </span>
                                    </div>

                                    <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      <span className="flex-1 block cursor-pointer w-full border-b-2 hover:border-b-primary overflow-hidden whitespace-nowrap text-ellipsis"
                                            onClick={() => setAddRole(true)}>
                                        {editedContributorRole ?? 'Select Role'}
                                      </span>

                                      {addRole && (
                                          <div>
                                            <Transition.Root show={addRole} as={Fragment}>
                                              <Dialog
                                                  as="div"
                                                  className="relative z-10"
                                                  onClose={() => setAddRole(!addRole)}
                                              >
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                </Transition.Child>

                                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                    >
                                                      <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                        <div>
                                                          <div className="text-lg font-normal px-4 pt-5">
                                                            <h1>Add Role to Contributor</h1>
                                                            <div className="pt-4">

                                                              <h1 className="text-xs text-primary font-semibold">
                                                                <span className="text-xs text-gray-500 ml-1 font-light">
                                                                  Roles
                                                                </span>
                                                              </h1>
                                                            </div>
                                                          </div>

                                                          <div className="bg-white">
                                                            <div className="mx-auto max-w-7xl px-4 py-4">
                                                              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                                                                <dl>
                                                                  <div className="grid items-center gap-2 mb-4 border-b pb-4">
                                                                    <ul className="max-h-[300px] min-h-[80px] overflow-y-auto relative list-none">
                                                                      {roles.map((role, i) => (
                                                                          <li key={i} className="p-1 cursor-pointer hover:text-primary" onClick={
                                                                            () => {
                                                                              setAddRole(!addRole)
                                                                              setEditedContributorRole(role)
                                                                            }
                                                                          }>
                                                                            {role}
                                                                          </li>
                                                                      ))}
                                                                    </ul>
                                                                  </div>
                                                                </dl>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                          <div></div>
                                                          <div className="flex items-center gap-4">
                                                            <button
                                                                type="button"
                                                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                                onClick={() =>
                                                                    setAddRole(!addRole)
                                                                }
                                                            >
                                                              Cancel
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </Dialog.Panel>
                                                    </Transition.Child>
                                                  </div>
                                                </div>
                                              </Dialog>
                                            </Transition.Root>
                                          </div>
                                      )}
                                    </div>

                                    <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      <div className="flex items-center w-5/6 gap-4">
                                        <span
                                            onClick={() => setAddInstrument(true)}
                                            className="flex-1 block cursor-pointer w-full border-b-2 hover:border-b-primary overflow-hidden whitespace-nowrap text-ellipsis">
                                          {editedContributorInstrument ?? 'Select Instrument'}
                                        </span>
                                      </div>

                                      {addInstrument && (
                                          <div>
                                            <Transition.Root show={addInstrument} as={Fragment}>
                                              <Dialog
                                                  as="div"
                                                  className="relative z-10"
                                                  onClose={() => setAddInstrument(!addInstrument)}
                                              >
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                </Transition.Child>

                                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                    >
                                                      <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                        <div>
                                                          <div className="text-lg font-normal px-4 pt-5">
                                                            <h1>Add Instrument to Contributor</h1>
                                                            <div className="pt-4">

                                                              <h1 className="text-xs text-primary font-semibold">
                                                                  <span className="text-xs text-gray-500 ml-1 font-light">
                                                                    Instruments
                                                                  </span>
                                                              </h1>
                                                            </div>
                                                          </div>

                                                          <div className="bg-white">
                                                            <div className="mx-auto max-w-7xl px-4 py-4">
                                                              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                                                                <dl>
                                                                  <div className="grid items-center gap-2 mb-4 border-b pb-4">
                                                                    <ul className="max-h-[300px] min-h-[80px] overflow-y-auto relative list-none">
                                                                      {instruments.map((instrument, i) => (
                                                                          <li key={i} className="p-1 cursor-pointer hover:text-primary" onClick={
                                                                            () => {
                                                                              setAddInstrument(!addInstrument)
                                                                              setEditedContributorInstrument(instrument)
                                                                            }
                                                                          }>
                                                                            {instrument}
                                                                          </li>
                                                                      ))}
                                                                    </ul>
                                                                  </div>
                                                                </dl>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                          <div></div>
                                                          <div className="flex items-center gap-4">
                                                            <button
                                                                type="button"
                                                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                                onClick={() =>
                                                                    setAddInstrument(!addInstrument)
                                                                }
                                                            >
                                                              Cancel
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </Dialog.Panel>
                                                    </Transition.Child>
                                                  </div>
                                                </div>
                                              </Dialog>
                                            </Transition.Root>
                                          </div>
                                      )}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div className="flex w-full items-center justify-end gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0 focus:outline-none"
                                    onClick={closeModalContributor}
                                >
                                  Cancel
                                </button>

                                <button
                                    onClick={createContributorData}
                                    className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}

          {editContributorModal && (
              <>
                <Transition.Root show={editContributorModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={
                        () => setEditContributorModal(!editContributorModal)
                      }
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Edit Contributor</h1>
                                <p className="text-sm font-light mt-2 text-[#757575] flex">
                                  Select role and instrument to add contributor.
                                </p>
                              </div>
                              <div className="relative w-ful">
                                <div className="overflow-visible">
                                  <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                    <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Name
                                    </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        Role
                                      </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        Instrument
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="relative w-ful py-4">
                                <div className="overflow-visible">
                                  <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                    <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                      <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                        {editingContributorName}
                                      </span>
                                    </div>
                                    <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      <div className="flex items-center w-5/6 gap-4">
                                          <span className="flex-1 block cursor-pointer w-full border-b-2 hover:border-b-primary overflow-hidden whitespace-nowrap text-ellipsis"
                                                onClick={() => setEditRole(true)}>
                                            {editingContributorRole}
                                          </span>

                                        {editRole && (
                                            <div>
                                              <Transition.Root show={editRole} as={Fragment}>
                                                <Dialog
                                                    as="div"
                                                    className="relative z-10"
                                                    onClose={() => setEditRole(!editRole)}
                                                >
                                                  <Transition.Child
                                                      as={Fragment}
                                                      enter="ease-out duration-300"
                                                      enterFrom="opacity-0"
                                                      enterTo="opacity-100"
                                                      leave="ease-in duration-200"
                                                      leaveFrom="opacity-100"
                                                      leaveTo="opacity-0"
                                                  >
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                  </Transition.Child>

                                                  <div className="fixed inset-0 z-10 overflow-y-auto">
                                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                      <Transition.Child
                                                          as={Fragment}
                                                          enter="ease-out duration-300"
                                                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                          leave="ease-in duration-200"
                                                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                      >
                                                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                          <div>
                                                            <div className="text-lg font-normal px-4 pt-5">
                                                              <h1>Edit Role for Contributor</h1>
                                                              <div className="pt-4">
                                                                <h1 className="text-xs text-primary font-semibold">
                                                                    <span className="text-xs text-gray-500 ml-1 font-light">
                                                                      Roles
                                                                    </span>
                                                                </h1>
                                                              </div>
                                                            </div>

                                                            <div className="bg-white">
                                                              <div className="mx-auto max-w-7xl px-4 py-4">
                                                                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                                                                  <dl>
                                                                    <div className="grid items-center gap-2 mb-4 border-b pb-4">
                                                                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto relative list-none">
                                                                        {roles.map((role, i) => (
                                                                            <li key={i} className="p-1 cursor-pointer hover:text-primary" onClick={
                                                                              () => {
                                                                                setEditRole(!editRole)
                                                                                setEditingContributorRole(role)
                                                                              }
                                                                            }>
                                                                              {role}
                                                                            </li>
                                                                        ))}
                                                                      </ul>
                                                                    </div>
                                                                  </dl>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                            <div></div>
                                                            <div className="flex items-center gap-4">
                                                              <button
                                                                  type="button"
                                                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                                  onClick={() =>
                                                                      setEditRole(!editRole)
                                                                  }
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </Dialog.Panel>
                                                      </Transition.Child>
                                                    </div>
                                                  </div>
                                                </Dialog>
                                              </Transition.Root>
                                            </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      <div className="flex items-center w-5/6 gap-4">
                                          <span className="flex-1 block cursor-pointer w-full border-b-2 hover:border-b-primary overflow-hidden whitespace-nowrap text-ellipsis"
                                                onClick={() => setEditInstrument(true)}>
                                            {editingContributorInstrument}
                                          </span>


                                        {editInstrument && (
                                            <div>
                                              <Transition.Root show={editInstrument} as={Fragment}>
                                                <Dialog
                                                    as="div"
                                                    className="relative z-10"
                                                    onClose={() => setEditInstrument(!editInstrument)}
                                                >
                                                  <Transition.Child
                                                      as={Fragment}
                                                      enter="ease-out duration-300"
                                                      enterFrom="opacity-0"
                                                      enterTo="opacity-100"
                                                      leave="ease-in duration-200"
                                                      leaveFrom="opacity-100"
                                                      leaveTo="opacity-0"
                                                  >
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                  </Transition.Child>

                                                  <div className="fixed inset-0 z-10 overflow-y-auto">
                                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                      <Transition.Child
                                                          as={Fragment}
                                                          enter="ease-out duration-300"
                                                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                          leave="ease-in duration-200"
                                                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                      >
                                                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                          <div>
                                                            <div className="text-lg font-normal px-4 pt-5">
                                                              <h1>Edit Instrument for Contributor</h1>
                                                              <div className="pt-4">
                                                                <h1 className="text-xs text-primary font-semibold">
                                                                    <span className="text-xs text-gray-500 ml-1 font-light">
                                                                      Instruments
                                                                    </span>
                                                                </h1>
                                                              </div>
                                                            </div>

                                                            <div className="bg-white">
                                                              <div className="mx-auto max-w-7xl px-4 py-4">
                                                                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                                                                  <dl>
                                                                    <div className="grid items-center gap-2 mb-4 border-b pb-4">
                                                                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto relative list-none">
                                                                        {instruments.map((role, i) => (
                                                                            <li key={i} className="p-1 cursor-pointer hover:text-primary" onClick={
                                                                              () => {
                                                                                setEditInstrument(!editInstrument)
                                                                                setEditingContributorInstrument(role)
                                                                              }
                                                                            }>
                                                                              {role}
                                                                            </li>
                                                                        ))}
                                                                      </ul>
                                                                    </div>
                                                                  </dl>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                            <div></div>
                                                            <div className="flex items-center gap-4">
                                                              <button
                                                                  type="button"
                                                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                                  onClick={() =>
                                                                      setEditInstrument(!editInstrument)
                                                                  }
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </Dialog.Panel>
                                                      </Transition.Child>
                                                    </div>
                                                  </div>
                                                </Dialog>
                                              </Transition.Root>
                                            </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div className="flex w-full items-center justify-end gap-4">

                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                    onClick={() => setEditContributorModal(!editContributorModal)}
                                >
                                  Cancel
                                </button>

                                <button
                                    onClick={editedAddedContributorData}
                                    className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}

          {deleteModalContributor && (
              <>
                <Transition.Root show={deleteModalContributor} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeModalDeleteContributor}
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Remove contributor</h1>
                                <p className="text-sm font-light py-4 text-[#757575] flex">
                                  Are you sure to remove this contributor?
                                </p>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                    onClick={closeModalDeleteContributor}
                                >
                                  Cancel
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                    onClick={deleteContributorData}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}
        </div>

        {/* METADATA */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase">
            Metadata
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Audio File
          </div>
          <div className="border border-[#e2e2e2] mx-6 my-4">
            <div className="flex items-center gap-48 px-6 py-4">
              <div className="flex items-center gap-2">
                <PlayCircleIcon className="w-6 h-6 text-primary" />
                <span className="text-xs font-bold">Stereo</span>
              </div>
              {asset.audioFile ? (
                <div>{asset.audioFile.name}</div>
              ) : (
                <div className="flex items-center gap-2 hover:bg-gray-100 rounded-primary py-1 px-2 cursor-pointer">
                  <ArrowUpOnSquareIcon className="w-6 h-6 text-primary" />
                  <label
                    className="text-sm uppercase text-primary cursor-pointer"
                    htmlFor="audioFile"
                  >
                    {audioLoading ? "Loading..." : "Upload File"}
                  </label>
                  <input
                    className="hidden w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                    id="audioFile"
                    type="file"
                    accept="audio/*"
                    onChange={onAudioFileChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Main
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label htmlFor="title" className="text-xs text-[#5F5F60B3]">
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                defaultValue={assetTitle}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="displayArtists"
                className="text-xs text-[#5F5F60B3]"
              >
                Display Artists
              </label>
              <input
                type="text"
                name="displayArtists"
                id="displayArtists"
                onChange={onChange}
                defaultValue={assetDisplayArtists}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="trackVersion"
                className="text-xs text-[#5F5F60B3]"
              >
                Track Version
              </label>
              <input
                type="text"
                name="trackVersion"
                id="trackVersion"
                onChange={onChange}
                defaultValue={assetTrackVersion}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label htmlFor="isrc" className="text-xs text-[#5F5F60B3]">
                ISRC
              </label>
              <input
                type="text"
                name="isrc"
                id="isrc"
                onChange={onChange}
                defaultValue={assetIsrc}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Asset copyright
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label
                htmlFor="p_CopyrightYear"
                className="text-xs text-[#5F5F60B3]"
              >
                ℗ Year
              </label>
              <input
                type="text"
                name="p_CopyrightYear"
                id="p_CopyrightYear"
                onChange={onChange}
                defaultValue={assetP_CopyrightYear}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="p_CopyrightLine"
                className="text-xs text-[#5F5F60B3]"
              >
                ℗ Line
              </label>
              <input
                type="text"
                name="p_CopyrightLine"
                id="p_CopyrightLine"
                onChange={onChange}
                defaultValue={assetP_CopyRightLine}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Genre
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                htmlFor="productGenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Genre
              </label>
              <input
                type="text"
                name="genre"
                id="genre"
                defaultValue={
                  selectedGenreName ? selectedGenreName : assetGenre
                }
                onClick={() => setGenreModal(!genreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {genreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleGenreSearch}
                          placeholder="Type here to search for genre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredGenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedGenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                          </div>
                        ))}
                      </ul>
                      {filteredGenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() => setGenreModal(!genreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productSubgenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Subgenre
              </label>
              <input
                type="text"
                name="subgenre"
                id="subgenre"
                defaultValue={
                  selectedSubgenreName ? selectedSubgenreName : assetSubgenre
                }
                onClick={() => setSubgenreModal(!subgenreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {subgenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={addNewSubgenre}
                          placeholder="Type here to search for subgenre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredSubgenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedSubgenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex justify-between relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                            <TrashIcon
                              onClick={() => deleteSubgenre(opt.id)}
                              className="w-6 h-6 text-gray-500 hover:bg-gray-100 rounded-full"
                            />
                          </div>
                        ))}
                      </ul>
                      {filteredSubgenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={addNewSubgenreRequest}
                          className={
                            newSubgenre
                              ? "py-1.5 px-2 text-primary h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                              : "py-1.5 px-2 text-gray-200"
                          }
                        >
                          Add New
                        </button>
                        <button
                          onClick={() => setSubgenreModal(!subgenreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productAlternateGenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Alternate Genre
              </label>
              <input
                type="text"
                name="productAlternateGenre"
                id="productAlternateGenre"
                defaultValue={
                  selectedAlternateGenreName
                    ? selectedAlternateGenreName
                    : assetAlternateGenre
                }
                onClick={() => setAlternateGenreModal(!alternateGenreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {alternateGenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleAlternateGenreSearch}
                          placeholder="Type here to search for alternate genre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredAlternateGenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedAlternateGenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                          </div>
                        ))}
                      </ul>
                      {filteredAlternateGenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() =>
                            setAlternateGenreModal(!alternateGenreModal)
                          }
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productAlternateSubgenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Alternate Subgenre
              </label>
              <input
                type="text"
                name="productAlternateSubgenre"
                id="productAlternateSubgenre"
                defaultValue={
                  selectedAlternateSubgenreName
                    ? selectedAlternateSubgenreName
                    : assetAlternateSubgenre
                }
                onClick={() =>
                  setAlternateSubgenreModal(!alternateSubgenreModal)
                }
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {alternateSubgenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={addNewAlternateSubgenre}
                          placeholder="Type here to search for alternate subgenre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredAlternateSubgenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedAlternateSubgenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex justify-between relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                            <TrashIcon
                              onClick={() => deleteAlternateSubgenre(opt.id)}
                              className="w-6 h-6 text-gray-500 hover:bg-gray-100 rounded-full"
                            />
                          </div>
                        ))}
                      </ul>
                      {filteredAlternateSubgenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={addNewSubgenreRequest}
                          className={
                            newSubgenre
                              ? "py-1.5 px-2 text-primary h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                              : "py-1.5 px-2 text-gray-200"
                          }
                        >
                          Add New
                        </button>
                        <button
                          onClick={() => setSubgenreModal(!subgenreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Lyrics
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label htmlFor="lyrics" className="text-xs text-[#5F5F60B3]">
                Lyrics
              </label>
              <input
                type="text"
                name="lyrics"
                id="lyrics"
                readOnly
                onClick={handleLyricsModal}
                defaultValue={assetLyrics ? "Lyrics added" : "No lyrics"}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2 cursor-pointer"
              />
            </div>
            {lyricsModal && (
              <div>
                <Transition.Root show={lyricsModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={handleLyricsModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Lyrics</h1>
                              </div>
                              <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                                <div className="relative w-full">
                                  <textarea
                                    name="assetLyrics"
                                    id="assetLyrics"
                                    onChange={onChange}
                                    defaultValue={assetLyrics}
                                    className="block w-full border border-gray-300 p-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary focus:border-primary"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                  onClick={handleLyricsModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            )}
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Language
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                onClick={() => setMetadataLanguageModal(true)}
                className="text-xs text-[#5F5F60B3] "
              >
                Metadata Language
              </label>
              <div
                onClick={() => setMetadataLanguageModal(true)}
                className="block w-full border-b cursor-pointer border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2 relative"
              >
                {selectedLanguage ? selectedLanguage : assetLanguage}
              </div>
              {metadataLanguageModal && (
                <div className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] top-0 left-0 rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleTranslationSearch}
                          placeholder="Type here to search for language"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredLanguage.map((language, index) => (
                          <div
                            onClick={() =>
                              handleSelectedMetadataLanguage(language)
                            }
                            key={index}
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{language}</span>
                          </div>
                        ))}
                        {filteredLanguage.length === 0 ? (
                          <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                            Could not find anything
                          </div>
                        ) : null}
                      </ul>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div
                        onClick={() => setMetadataLanguageModal(false)}
                        className="flex flex-row-reverse pt-2"
                      >
                        <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="audioLanguage"
                className="text-xs text-[#5F5F60B3]"
              >
                Audio Language
              </label>
              <input
                type="text"
                name="audioLanguage"
                id="audioLanguage"
                defaultValue={
                  selectedAudioLanguage
                    ? selectedAudioLanguage
                    : assetAudioLanguage
                }
                onClick={() => setAudioLanguageModal(true)}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {audioLanguageModal && (
                <div className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] top-0 left-0 rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleTranslationSearch}
                          placeholder="Type here to search for language"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredLanguage.map((language, index) => (
                          <div
                            onClick={() =>
                              handleSelectedAudioLanguage(language)
                            }
                            key={index}
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{language}</span>
                          </div>
                        ))}
                        {filteredLanguage.length === 0 ? (
                          <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                            Could not find anything
                          </div>
                        ) : null}
                      </ul>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div
                        onClick={() => setAudioLanguageModal(false)}
                        className="flex flex-row-reverse pt-2"
                      >
                        <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* OTHER */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase">
            Other
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label
                htmlFor="recordingYear"
                className="text-xs text-[#5F5F60B3]"
              >
                Recording Year
              </label>
              <input
                type="text"
                name="recordingYear"
                id="recordingYear"
                onChange={onChange}
                defaultValue={assetRecordingYear}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="recordingLocation"
                className="text-xs text-[#5F5F60B3]"
              >
                Recording Location
              </label>
              <input
                type="text"
                name="recordingLocation"
                id="recordingLocation"
                onChange={onChange}
                defaultValue={assetRecordingLocation}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="rightsHolderName"
                className="text-xs text-[#5F5F60B3]"
              >
                Rights Holder Name
              </label>
              <input
                type="text"
                name="rightsHolderName"
                id="rightsHolderName"
                onChange={onChange}
                defaultValue={assetRightsHolderName}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="countryOfRecording"
                className="text-xs text-[#5F5F60B3]"
              >
                Country Of Recording
              </label>
              <input
                type="text"
                name="countryOfRecording"
                id="countryOfRecording"
                onChange={onChange}
                defaultValue={assetCountryOfRecording}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="countryOfCommissioning"
                className="text-xs text-[#5F5F60B3]"
              >
                Country Of Commissioning
              </label>
              <input
                type="text"
                name="countryOfCommissioning"
                id="countryOfCommissioning"
                onChange={onChange}
                defaultValue={assetCountryOfCommissioning}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="rightsOwnershipName"
                className="text-xs text-[#5F5F60B3]"
              >
                Rights Ownership Name
              </label>
              <input
                type="text"
                name="rightsOwnershipName"
                id="rightsOwnershipName"
                onChange={onChange}
                defaultValue={assetRightsOwnershipName}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="rightsContractBeginDate"
                className="text-xs text-[#5F5F60B3]"
              >
                Rights Contract Begin Date
              </label>
              <div className="flex items-center gap-1">
                <DatePicker
                  selected={
                    rightsContractBeginDate
                      ? new Date(rightsContractBeginDate)
                      : null
                  }
                  onChange={onRightsContractBeginDateChange}
                  dateFormat="yyyy/MM/dd"
                  className="block cursor-pointer w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
                />
                <CalendarDaysIcon className="w-6 h-6 text-primary" />
              </div>
            </div>
          </div>
        </div>

        {/* TRANSLATIONS */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase mb-4">
            Translations
          </div>
          <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Language
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Artist Name
              </span>
            </div>
            <div className="flex-[0_1_200px] mr-[10px] text-xs text-right relative">
              <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                <div
                  onClick={() => setAddTranslation(true)}
                  className="flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer"
                >
                  <PlusIcon className="w-5 h-5 text-primary" />
                  <span className="text-right">Add Translation</span>
                  <ChevronDownIcon className="w-5 h-5- text-primary" />
                </div>
                {addTranslation && (
                  <div className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                    <div className="py-2 px-4">
                      <div className="flex flex-col">
                        <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                          <input
                            type="text"
                            onChange={handleTranslationSearch}
                            placeholder="Type here to search for language"
                            className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                          />
                        </div>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                          {filteredLanguage.map((language, index) => (
                            <div
                              onClick={() => handleSelectedLanguage(language)}
                              key={index}
                              className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                index === 0 && "bg-gray-200"
                              }`}
                            >
                              <span className="capitalize">{language}</span>
                            </div>
                          ))}
                          {filteredLanguage.length === 0 ? (
                            <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                              Could not find anything
                            </div>
                          ) : null}
                        </ul>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <div
                          onClick={closeTranslationModal}
                          className="flex flex-row-reverse pt-2"
                        >
                          <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {translations.length === 0 && (
            <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center">
              No items available
            </div>
          )}

          {Array.isArray(assetTranslations) &&
            assetTranslations.map((translation, index) => {
              return (
                <div
                  key={translation.id}
                  className={
                    index % 2 === 0
                      ? "flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs"
                      : "bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs"
                  }
                >
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.language}
                    </span>
                  </div>
                  <div className="flex-[1_1_200px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.title}
                    </span>
                  </div>
                  <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                      <div className="flex items-center gap-6">
                        <div
                          onClick={() =>
                            selectArtistNameForEditing(
                              translation.title,
                              translation.id
                            )
                          }
                          className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                        >
                          <PencilIcon className="w-6 h-6 text-gray-500" />
                        </div>
                        <div
                          onClick={() =>
                            handleDeleteModalTranslation(translation.id)
                          }
                          className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                        >
                          <TrashIcon className="w-6 h-6 text-gray-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {deleteModalTranslation && (
            <>
              <Transition.Root show={deleteModalTranslation} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={closeModalDeleteTranslation}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Remove translation</h1>
                              <p className="text-sm font-light py-4 text-[#757575] flex">
                                Are you sure you want to remove this translation
                                from the Product?
                              </p>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                onClick={handleDeleteModal}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                onClick={deleteAddedTranslationData}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
          {openTranslationModal && (
            <>
              <Transition.Root show={openTranslationModal} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={closeModalT}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Add translations</h1>
                              <p className="text-sm font-light mt-2 text-[#757575] flex">
                                Insert translations by clicking the{" "}
                                <PencilIcon className="w-6 h-6 px-1" /> button
                                and saving it.
                              </p>
                            </div>
                            <div className="relative w-ful">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                  </div>
                                  <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative w-ful py-4">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {assetTitle}
                                    </span>
                                  </div>
                                  <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                    {editTranslation ? (
                                      <div className="flex items-center w-5/6 gap-4">
                                        <input
                                          type="text"
                                          defaultValue={editedArtistName}
                                          onChange={handleChangeTranslation}
                                          className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                        />
                                        <button
                                          onClick={createTranslationData}
                                          className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <span className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editedArtistName}
                                      </span>
                                    )}
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                      <PencilSquareIcon
                                        onClick={openEditTranslationModal}
                                        className="text-black w-6 h-6"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0 focus:outline-none"
                                onClick={closeModalT}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
          {editAddedTranslation && (
            <>
              <Transition.Root show={editAddedTranslation} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={selectArtistNameForEditing}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Add translations</h1>
                              <p className="text-sm font-light mt-2 text-[#757575] flex">
                                Insert translations by clicking the{" "}
                                <PencilIcon className="w-6 h-6 px-1" /> button
                                and saving it.
                              </p>
                            </div>
                            <div className="relative w-ful">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                  </div>
                                  <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative w-ful py-4">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {assetTitle}
                                    </span>
                                  </div>
                                  <div className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                    {editTranslation ? (
                                      <div className="flex items-center w-5/6 gap-4">
                                        <input
                                          type="text"
                                          defaultValue={editingArtistName}
                                          onChange={handleChangeTranslation}
                                          className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                        />
                                        <button
                                          onClick={editedAddedTranslationData}
                                          className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <span className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editingArtistName}
                                      </span>
                                    )}
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                      <PencilSquareIcon
                                        onClick={openEditTranslationModal}
                                        className="text-black w-6 h-6"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                onClick={selectArtistNameForEditing}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
        </div>
      </div>

      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        <div className="flex items-center gap-2 py-6">
          <div className="flex ml-auto">
            <button
                onClick={onSubmitClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
