import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredAsset: [],
}

const searchAsset = createSlice({
  name: 'searchAsset',
  initialState,
  reducers: {
    setFilteredAsset: (state, action) => {
      state.filteredAsset = [...action.payload];
    },
  },
});

export const { setFilteredAsset } = searchAsset.actions;
export default searchAsset.reducer;
