import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterData: {
    product: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    asset: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    artist: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    label: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    people: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    publisher: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    },
    release: {
      query: '',
      sorting: 'date_desc',
      partner: null,
      currentPage: 0
    }
  },
}

const filterData = createSlice({
  name: 'filterData',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      const { category, query } = action.payload;
      state.filterData[category].query = query;
    },
    setSorting: (state, action) => {
      const { category, sorting } = action.payload;
      state.filterData[category].sorting = sorting;
    },
    setPartner: (state, action) => {
      const { category, partner } = action.payload;
      state.filterData[category].partner = partner;
    },
    setCurrentPage: (state, action) => {
      const { category, currentPage } = action.payload;
      state.filterData[category].currentPage = currentPage;
    },
    clearFilters: (state, action) => {
      const { category } = action.payload;
      state.filterData[category] = {
        query: '',
        sorting: 'date_desc',
        partner: null,
        currentPage: 0
      };
    },
  },
});

export const { setQuery, setSorting, setPartner, setCurrentPage, clearFilters } = filterData.actions;
export default filterData.reducer;
