import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const peopleTranslationData = createSlice({
    name: 'peopleTranslationData',
    initialState,
    reducers: {
        setPeopleTranslationData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setPeopleTranslationData } = peopleTranslationData.actions;
export default peopleTranslationData.reducer;
