import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    peopleData: [],
}

const peopleData = createSlice({
    name: 'peopleData',
    initialState,
    reducers: {
        setPeopleData: (state, action) => {
            state.peopleData = action.payload;
        },
    },
});

export const { setPeopleData } = peopleData.actions;
export default peopleData.reducer;
