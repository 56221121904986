import AppBar from "../components/AppBar";
import Navbar from "../components/common/Navbar";
import Header from "../components/Header";
import ArtistContent from "../components/artists/ArtistContent";
import CreateArtist from "../components/artists/CreateArtist";
import { setFilteredArtist } from "../stores/searchArtist";
import { useSelector } from "react-redux";

export default function Artists() {
  const data = useSelector((state) => state.artistData.artistData);

  return (
    <>
    <Header/>
    <AppBar/>
      <Navbar
          category="artist"
          searchLabel="Search for artist"
          data={data}
          component={<CreateArtist />}
          filterAction={setFilteredArtist}
          hideCreateButton={false}
      />
    <ArtistContent/>
    </>
  )
}
