import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredArtist: [],
}

const searchArtist = createSlice({
  name: 'searchArtist',
  initialState,
  reducers: {
    setFilteredArtist: (state, action) => {
      state.filteredArtist = [...action.payload];
    },
  },
});

export const { setFilteredArtist } = searchArtist.actions;
export default searchArtist.reducer;
