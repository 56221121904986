import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    openModal: false,
    actionModal: '',
}

const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setOpenModal: (state, action) => {
            state.openModal = action.payload
        },
        setActionModal: (state, action) => {
            state.actionModal = action.payload
        }
    }
})

export const {setOpenModal, setActionModal} = modal.actions
export default modal.reducer