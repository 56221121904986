import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import React from "react";

const RightIcon = () => {
    return (
        <ChevronLeftIcon className='text-gray-300 w-6 h-6' />
    )
}

const LeftIcon = () => {
    return (
        <ChevronRightIcon className='text-gray-300 w-6 h-6' />
    )
}

export { RightIcon, LeftIcon };