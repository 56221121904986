import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { setReleaseData } from "../../stores/releaseData";
import {setCurrentPage } from "../../stores/filterData";
import { getPartnerIds } from "../../utils/lib/get-partners";
import { getReleases } from "../../utils/lib/get-releases";
import Loading from "../Loading";
import { RightIcon, LeftIcon } from "../common/icons";
import {useCookies} from "react-cookie";

export default function ReleaseContent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { credential, partnerIds } = useSelector((state) => state.user);
  const { filteredRelease } = useSelector((state) => state.searchRelease);
  const { currentPage } = useSelector((state) => state.filterData.filterData["release"]);
  const [cookies, setCookie] = useCookies(["selectedPartnerId"]);

  //product table
  const perPage = 10;

  const handlePageChange = (selectedPage) => {
    dispatch(setCurrentPage({ category: "release", currentPage: selectedPage.selected }));
  };

  const getCurrentData = () => {
    const startIndex = currentPage * perPage;
    const endIndex = startIndex + perPage;
    return filteredRelease.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const fetchReleaseData = async () => {
      const headers = {
        Authorization: `Bearer ${credential}`,
        PartnerId: getPartnerIds(partnerIds),
      };

      try {
        const releases = await getReleases(headers);
        dispatch(setReleaseData(releases));

        setLoading(false);
      } catch (error) {
        toast.error("API isteği sırasında bir hata oluştu:", error);
        setLoading(true);
      }
    };
    fetchReleaseData();
  }, [credential, partnerIds, dispatch]);

  if (loading) {
    return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
  }

  const handleClick = (id) => {
    setCookie("selectedPartnerId", id, { path: "/" });
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        {getCurrentData().length === 0 ? (
          <div className="flex items-center justify-center text-[#e2e2e2] text-2xl mt-4">
            No release data
          </div>
        ) : (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4">
              <div className="w-full text-sm text-left">
                <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      LABEL
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Created Time
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Last Acton?
                    </div>
                  </div>
                </div>
                <div>
                  {getCurrentData().map((item, index) => (
                    <div
                      key={item.id}
                      className="flex items-center h-[50px] py-3 p-6 text-primary font-light border-b border-[#E2E2E2] bg-white text-xs"
                      onClick={() => handleClick(item.partner?.id)}
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div
                            className={`inline-block max-w-full overflow-hidden whitespace-nowrap ${item.isRead ? "font-light" : "font-bold"}`}
                        >
                          <Link to={item.id}>{item.name}</Link>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                          {item.createdTime}
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                          {item.lastAction}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <ReactPaginate
              previousLabel={<RightIcon />}
              nextLabel={<LeftIcon />}
              pageCount={Math.ceil(filteredRelease.length / perPage)}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"text-[#212121] text-[14px]"}
              className="flex items-center justify-end  gap-4 py-6 text-gray-300 text-[14px]"
            />
          </>
        )}
      </div>
    </>
  );
}
