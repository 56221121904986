import axios from "axios";
import { cleanHeaders } from "../clean-headers";

const getPublishers = async (params) => {
  const headers = cleanHeaders(params);

  try {
    return await axios.get(
        "https://ddex-384918.ew.r.appspot.com/publisher",
        {headers}
    ).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export { getPublishers };
