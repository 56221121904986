import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';
import {ChevronRightIcon, CheckIcon, XMarkIcon, ArrowPathIcon, HandThumbDownIcon} from '@heroicons/react/24/outline';
import Loading from '../Loading';
import { setActionModal } from '../../stores/modal';
import {useCookies} from "react-cookie";
import {Dialog, Transition, Switch} from "@headlessui/react";
import {toast, ToastContainer} from "react-toastify";

const ReleaseDetail = () => {
    const { id } = useParams();
    const [release, setRelease] = useState('');
    const { credential } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [releaseStatus, setReleaseStatus] = useState("");
    const { actionModal } = useSelector(state => state.modal);
    const [cookie] = useCookies(["selectedPartnerId"]);
    const [rejectedMsg, setRejectedMsg] = useState("");


    const delivers = [
        "YOUTUBE_PREMIUM",
        "YOUTUBE_CONTENTID",
        "Fuga",
        "MUUD"
    ];

    const [enabled, setEnabled] = useState(delivers.map(() => false));

    const headerSet = {
        Authorization: `Bearer ${credential}`,
    };

    useEffect(() => {
        const fetchRelease = async () => {
            const headers = {
                ...headerSet,
                partnerId: cookie.selectedPartnerId
            };
            try {
                const response = await axios.get(`https://ddex-384918.ew.r.appspot.com/release/${id}`, { headers });
                const releaseData = response.data;
                setRelease(releaseData);
                setReleaseStatus(releaseData.lastAction);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error(error.response?.data?.message);
                closeActionModal();
                console.error("API isteği sırasında bir hata oluştu:", error);
            }
        };
        fetchRelease();
    }, []);

    const updateRelease = async (body) => {

        setLoading(true);
        const headers = {
            ...headerSet,
            partnerId: cookie.selectedPartnerId,
            "Content-Type": "application/json",
        };

        try {
            await axios.post(
                `https://ddex-384918.ew.r.appspot.com/release/${id}/review`,
                body,
                { headers }
            );
            setLoading(false);
            setReleaseStatus(body.Result);
            closeActionModal();
            toast.success('Release has been updated successfully');

        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message);
            closeActionModal();
            console.error("API isteği sırasında bir hata oluştu:", error);
        }
    };

    const onDeliver = async (body) => {

        setLoading(true);
        const headers = {
            ...headerSet,
            partnerId: cookie.selectedPartnerId,
            "Content-Type": "application/json",
        };

        const selected = enabled
            .map((value, index) => (value ? delivers[index] : null))
            .filter((value) => value !== null);

        try {
            await axios.post(
                `https://ddex-384918.ew.r.appspot.com/release/${id}/deliver`,
                {
                    Recipients: selected,
                },
                { headers }
            );
            toast.success('Release has been delivered successfully');
            setLoading(false);
            setReleaseStatus("Delivered");
            closeActionModal();
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message);
            closeActionModal();
            console.error("API isteği sırasında bir hata oluştu:", error);
        }
    };


    const onReDeliver = async () => {

        setLoading(true);
        const headers = {
            ...headerSet,
            partnerId: cookie.selectedPartnerId,
            "Content-Type": "application/json",
        };

        try {
            await axios.post(
                `https://ddex-384918.ew.r.appspot.com/release/${id}/redeliver`,
                {},
                { headers }
            );
            toast.success('Release has been redelivered successfully');
            setLoading(false);
            setReleaseStatus("ReDelivered");
            closeActionModal();
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message);
            closeActionModal();
            console.error("API isteği sırasında bir hata oluştu:", error);
        }
    };

    const onTakeDown = async () => {

        setLoading(true);
        const headers = {
            ...headerSet,
            partnerId: cookie.selectedPartnerId,
            "Content-Type": "application/json",
        };

        try {
            await axios.post(
                `https://ddex-384918.ew.r.appspot.com/release/${id}/takedown`,
                {},
                { headers }
            );
            toast.success('Release has been taken down successfully');
            setLoading(false);
            setReleaseStatus("TakenDown");
            closeActionModal();
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message);
            closeActionModal();
            console.error("API isteği sırasında bir hata oluştu:", error);
        }
    };

    if (loading) {
        return <Loading />
    }

    function KeyValueList({ obj, parentKey = "" }) {
        const items = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const fullKey = parentKey + key;
                items.push(
                    <div key={fullKey} className='flex items-center justify-start'>
                        <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                            <div className="relative w-[350px]">
                                <div className="inline-block bg-white font-normal text-gray-400 mr-1">
                                    {fullKey}:
                                </div>
                                <div>{value}</div>
                            </div>
                        </div>
                    </div>
                );
                if (typeof value === "object" && value !== null) {
                    items.push(<KeyValueList obj={value} parentKey={fullKey + "."} />);
                }
            }
        }
        return items;
    }


    const closeActionModal = () => {
        dispatch(setActionModal(''));
    }

    const toggleEnabled = (index) => {
        const newEnabled = [...enabled];
        newEnabled[index] = !newEnabled[index];
        setEnabled(newEnabled);
    };

    return (
        <>
            <ToastContainer autoClose={3000} />
            <Header />
            <div className="mx-auto max-w-7xl p-3 lg:px-8">
                <div className='flex items-center gap-2 py-6'>
                    <div>
                        <Link to='/catalog/products' className='text-[#555555] text-sm'>Catalog</Link>
                    </div>
                    <ChevronRightIcon className='w-4 h-4 text-[#555555]' />
                    <div>
                        <Link to='/catalog/releases' className='text-[#555555] text-sm'>Releases</Link>
                    </div>
                    <ChevronRightIcon className='w-4 h-4 text-[#555555]' />
                    <div>
                        <Link to={`/catalog/releases/${id}`} className='text-[#555555] text-sm'>{release.name}</Link>
                    </div>
                </div>

                <div className='bg-white border border-[#e2e2e2] py-2 px-6'>
                    <div className='flex items-center justify-between'>
                        <div className='text-sm font-light'>Release</div>
                        <div className='text-xs font-semibold text-[#BBBBBB]'>ID: <span className='pl-2 text-[#212121]'>{release.id}</span></div>
                    </div>
                </div>

                <div className='bg-white border border-t-0 border-[#e2e2e2] py-2 px-6'>
                    <div className='flex items-center'>
                        <div className='text-sm font-light'>Name:</div>
                        <div className='text-xs font-semibold text-[#212121] ml-2'>{release.name}</div>
                    </div>
                </div>

                <div className='bg-white border border-t-0 border-[#e2e2e2] py-2 px-6'>
                    <div className='flex items-center'>
                        <div className='text-sm font-light'>Last Action:</div>
                        <div className='text-xs font-semibold text-[#212121] ml-2'>{release.lastAction}</div>
                    </div>
                </div>

                <div className='flex flex-wrap mt-8 bg-white border border-[#e2e2e2] py-1 px-3'>

                    <div className='flex items-center justify-start'>
                        <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                            <div className="relative w-[350px]">
                                <div className="inline-block bg-white font-normal text-gray-400 mr-1">
                                    Product:
                                </div>
                                <div>{release.product?.title}</div>
                            </div>
                        </div>
                    </div>

                    <KeyValueList obj={release.product} />

                </div>
                {releaseStatus === 'Sent For Review' && (
                    <div className="flex gap-4 mt-4 p-4 bg-white border border-1">
                        <div
                            onClick={() => updateRelease({ Result: 'Accepted' })}
                            className="flex-1 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-green-500 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            <CheckIcon className="h-5 w-5 text-white" />
                            <span>Accept</span>
                        </div>
                        <div
                            onClick={() => dispatch(setActionModal('Reject'))}
                            className="flex-1 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-red px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-red/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            <XMarkIcon className="h-5 w-5 text-white" />
                            <span>Reject</span>
                        </div>
                    </div>
                )}


                {releaseStatus === 'Accepted' && (
                    <div className="flex gap-4 mt-4 p-4 bg-white border border-1">
                        <div
                            onClick={() => dispatch(setActionModal('Deliver'))}
                            className="flex-1 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-blue px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            <CheckIcon className="h-5 w-5 text-white" />
                            <span>Deliver</span>
                        </div>
                    </div>
                )}


                {releaseStatus === 'Delivered' && (
                    <div className="flex gap-4 mt-4 p-4 bg-white border border-1">
                        <div
                            onClick={() => dispatch(setActionModal('ReDeliver'))}
                            className="flex-1 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-green-500 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            <ArrowPathIcon className="h-5 w-5 text-white" />
                            <span>Re-Deliver</span>
                        </div>
                        <div
                            onClick={() => onTakeDown()}
                            className="flex-1 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-red px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-red/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            <HandThumbDownIcon className="h-5 w-5 text-white" />
                            <span>Take Down</span>
                        </div>
                    </div>
                )}

                {!!actionModal.length &&
                    <Transition.Root show={!!actionModal.length} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeActionModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        {actionModal === 'Deliver' && (
                                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                    <div>
                                                        <span className='text-lg font-light leading-[27px]'>Deliver Release</span>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <span className='font-light text-sm'>Select deliver addresses:</span>

                                                        <div className="flex flex-col flex-wrap gap-3 mt-3">
                                                            {delivers.map((deliver, index) => (
                                                                <div key={index}>
                                                                    <Switch.Group>
                                                                        <div className="flex items-center">
                                                                            <Switch
                                                                                checked={enabled[index]}
                                                                                onChange={() => toggleEnabled(index)}
                                                                                className={`${
                                                                                    enabled[index] ? 'bg-primary' : 'bg-passive'
                                                                                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-0`}
                                                                            >
                                                                            <span
                                                                                className={`${
                                                                                    enabled[index] ? 'translate-x-6' : 'translate-x-1'
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                                                            />
                                                                            </Switch>
                                                                            <Switch.Label className="ml-4 text-sm">
                                                                                {deliver.replace(/_/g, " ")}
                                                                            </Switch.Label>
                                                                        </div>
                                                                    </Switch.Group>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                                                        onClick={() => onDeliver()}>
                                                        DELIVER
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-100 rounded-md uppercase  sm:mt-0 sm:w-auto"
                                                        onClick={() => closeActionModal()}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                        )}

                                        {actionModal === 'ReDeliver' && (
                                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                        <span className='font-light text-sm'>Do you want to re-deliver the release?</span>
                                                    </div>
                                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                                        <button
                                                            type="button"
                                                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                                                            onClick={() => onReDeliver()}>
                                                            RE-DELIVER
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-100 rounded-md uppercase  sm:mt-0 sm:w-auto"
                                                            onClick={() => closeActionModal()}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </Dialog.Panel>
                                        )}

                                        {actionModal === 'Reject' && (
                                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                    <div>
                                                        <span className='text-lg font-light leading-[27px]'>Reject Release</span>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <span className='font-light text-sm'>Write an error message: (optional)</span>
                                                        <textarea
                                                            className="w-full h-24 mt-2 border border-[#e2e2e2] rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                            placeholder="Write an error message"
                                                            onChange={(e) => setRejectedMsg(e.target.value)}
                                                            defaultValue={rejectedMsg}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 sm:ml-3 sm:w-auto"
                                                        onClick={() => updateRelease({Result: "Declined", ErrorMessages: [rejectedMsg]})}
                                                    >
                                                        REJECT
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-100 rounded-md uppercase  sm:mt-0 sm:w-auto"
                                                        onClick={() => closeActionModal()}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                        )}
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                }

            </div>
        </>
    );
};

export default ReleaseDetail;
