import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import banner from "../assets/img/login-banner.jpg";
import { useDispatch } from "react-redux";
import { setLogin } from "../stores/auth";
import { useCookies } from "react-cookie";
import { getAllPartners } from "../utils/lib/get-partners";
import login from "../utils/lib/login";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);

  const LoginWithGoogleButton = () => {
    return (
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const credential = credentialResponse.credential;

          const headers = {
            Authorization: `Bearer ${credential}`,
          };

          let partnerIds;
          const response = await login(headers);
          const {isAdmin} = response

          if (isAdmin) {
              partnerIds = await getAllPartners(headers);
          }

          dispatch(
            setLogin({
              credential,
              isAuthenticated: true,
              isAdmin: isAdmin ?? null,
              partnerIds: partnerIds ?? null,
            })
          );
          setCookie("accessToken", credential, { path: "/" });
          navigate("/catalog/products");
        }}
        onError={() => {
          removeCookie("accessToken", { path: "/" });
          removeCookie("selectedPartnerId", { path: "/" });
          console.log("Login Failed");
        }}
      />
    );
  };

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center">
                Login with Google
              </h2>
            </div>
            <div className="my-8 flex items-center justify-center">
              <LoginWithGoogleButton />
            </div>
          </div>
        </div>
        <div className="relative w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={banner}
            alt="login"
          />
        </div>
      </div>
    </>
  );
}
