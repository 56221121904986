import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectTab } from "../stores/tabs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppBar() {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.tabs);
  const { isAdmin } = useSelector((state) => state.user);

  const handleTabClick = (name) => {
    dispatch(selectTab(name));
  };

  const currentTabs = isAdmin
    ? tabs
    : tabs.filter((item) => item.restricted !== true);

  return (
    <div className="border-b border-gray-200 bg-white">
      <div
        className="mx-auto max-w-7xl items-center justify-between lg:px-8"
        aria-label="Global"
      >
        <div className="sm:hidden">
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={currentTabs.find((tab) => tab.current).name}
          >
            {currentTabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="py-6">
          <h1 className="text-black text-3xl">Catalog</h1>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {currentTabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.href}
                  onClick={() => handleTabClick(tab.name)}
                  className={classNames(
                    tab.current
                      ? "border-primary text-primary"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium uppercase"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
