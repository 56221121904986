import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModal } from '../../stores/modal';
import { useSpring, animated } from "react-spring";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


export default function CreateArtist() {
    const { openModal } = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const { credential } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [createArtist, setCreateArtist] = useState('');
    const [artistName, setArtistName] = useState('');

    const closeModal = () => {
        dispatch(setOpenModal(false));
    }

    const labelProps = useSpring({
        top: isFocused ? "-16px" : "0",
        fontSize: isFocused ? "12px" : "16px",
    });

    const handleInputClick = () => {
        setIsFocused(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCreateArtist = async () => {
        const headers = { Authorization: `Bearer ${credential}`, 'Content-Type': 'application/json' };
        try {
            const response = await axios.post('https://ddex-384918.ew.r.appspot.com/artist', {
                name: artistName,
                countryOfOrigin: '',
                contactDetails: '',
                bookingAgent: '',
                biograhpy: ''
            }, {
                headers: headers
            });
            const createdPeople = response.data;
            setCreateArtist(createdPeople);
            dispatch(setOpenModal(false));
            window.location.reload();
        } catch (error) {
            toast.error('API isteği sırasında bir hata oluştu:', error);
        }

    };


    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                <div>
                                    <div className='text-lg font-normal px-4 pt-5'>
                                        <h1>Create Artist</h1>
                                    </div>
                                    <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                                        <div className="flex items-center justify-center w-44 relative">
                                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-40 h-40 rounded-primary cursor-pointer bg-[#e8e8e8] hover:bg-black hover:bg-opacity-50">
                                                <div className="flex flex-col items-center justify-center py-4 hover:before:content-['update']">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-28 h-28">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                                    </svg>
                                                </div>
                                                <input id="dropzone-file" type="file" className="hidden" />
                                            </label>
                                        </div>
                                        <div className="relative w-full">
                                            <animated.label
                                                className={isFocused ? "absolute left-0 inline-block bg-white px-1 font-normal text-gray-400" : "absolute top-0 left-0 inline-block bg-white px-1 font-normal text-gray-400"}
                                                style={labelProps}
                                            >
                                                Artist   name*
                                            </animated.label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={artistName}
                                                onChange={(e) => setArtistName(e.target.value)}
                                                onClick={handleInputClick}
                                                ref={inputRef}
                                                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                    <div>
                                    </div>
                                    <div className='flex items-center gap-4'>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md bg-primary px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 uppercase sm:col-start-2"
                                            onClick={handleCreateArtist}
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
