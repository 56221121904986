import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    artistData: [],
}

const artistData = createSlice({
    name: 'artistData',
    initialState,
    reducers: {
        setArtistData: (state, action) => {
            state.artistData = action.payload;
        },
    },
});

export const { setArtistData } = artistData.actions;
export default artistData.reducer;
