import AppBar from "../components/AppBar";
import Navbar from "../components/common/Navbar";
import PublisherContent from "../components/publishers/PublisherContent";
import Header from "../components/Header";
import {useSelector} from "react-redux";
import CreatePublisher from "../components/publishers/CreatePublisher";
import {setFilteredPublisher} from "../stores/searchPublisher";

export default function Labels() {
  const data = useSelector((state) => state.publisherData.publisherData);

  return (
    <>
    <Header/>
    <AppBar/>
      <Navbar
          category="publisher"
          searchLabel="Search for publisher"
          data={data}
          component={<CreatePublisher />}
          filterAction={setFilteredPublisher}
          hideCreateButton={false}
      />
      <PublisherContent/>
    </>
  )
}
