import { createSlice } from "@reduxjs/toolkit";

const storedTabs = localStorage.getItem("tabs");
const initialState = storedTabs
  ? JSON.parse(storedTabs)
  : [
      { name: "Products", href: "/catalog/products", current: true },
      { name: "Assets", href: "/catalog/assets", current: false },
      { name: "Artists", href: "/catalog/artists", current: false },
      { name: "Labels", href: "/catalog/labels", current: false },
      { name: "People", href: "/catalog/people", current: false },
      { name: "Publishers", href: "/catalog/publishers", current: false },
      {
        name: "Releases",
        href: "/catalog/releases",
        current: false,
        restricted: true,
      },
    ];

const tabs = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    selectTab: (state, action) => {
      const selectedTabName = action.payload;
      state.forEach((tab) => {
        if (tab.name === selectedTabName) {
          tab.current = true;
        } else {
          tab.current = false;
        }
      });
      localStorage.setItem("tabs", JSON.stringify(state));
    },
  },
});

export const { selectTab } = tabs.actions;
export default tabs.reducer;
