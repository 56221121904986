import AppBar from "../components/AppBar";
import AssetContent from "../components/assets/AssetContent";
import Navbar from "../components/common/Navbar";
import Header from "../components/Header";
import CreateAssets from "../components/assets/CreateAssets";
import { setFilteredAsset } from "../stores/searchAsset";
import { useSelector } from "react-redux";

export default function Assets() {
  const data = useSelector((state) => state.assetData.assetData);

  return (
    <>
    <Header/>
    <AppBar/>
      <Navbar
          category="asset"
          searchLabel="Search for asset"
          data={data}
          component={<CreateAssets />}
          filterAction={setFilteredAsset}
          hideCreateButton={false}
      />
    <AssetContent/>
    </>
  )
}
