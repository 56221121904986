import { configureStore } from "@reduxjs/toolkit"
import user from "./auth"
import tabs from "./tabs"
import modal from "./modal"
import labelData from "./labelData"
import searchLabel from "./searchLabel"
import publisherData from "./publisherData"
import searchPublisher from "./searchPublisher"
import peopleData from "./peopleData"
import searchPeople from "./searchPeople"
import publisherTranslationData from "./publisherTranslationData"
import labelTranslationData from "./labelTranslationData"
import peopleTranslationData from "./peopleTranslationData"
import peopleIdentifierData from "./peopleIdentifierData"
import artistData from "./artistData"
import searchArtist from "./searchArtist"
import assetData from "./assetData"
import searchAsset from "./searchAsset"
import productData from "./productData"
import releaseData from "./releaseData"
import searchProduct from "./searchProduct"
import searchRelease from "./searchRelease"
import filterData from "./filterData"

const store = configureStore({
  reducer: {
    user,
    tabs,
    modal,
    labelData,
    publisherData,
    peopleData,
    publisherTranslationData,
    labelTranslationData,
    peopleTranslationData,
    peopleIdentifierData,
    artistData,
    searchArtist,
    searchAsset,
    searchLabel,
    searchPeople,
    searchPublisher,
    searchProduct,
    searchRelease,
    assetData,
    productData,
    releaseData,
    filterData
  },
});

export default store;