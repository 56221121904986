import { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { setAssetData } from '../../stores/assetData'
import Loading from "../Loading"
import { getAssets } from "../../utils/lib/get-assets";
import {getPartnerIds} from "../../utils/lib/get-partners";
import {setCurrentPage} from "../../stores/filterData";
import { RightIcon, LeftIcon } from "../common/icons";
import {useCookies} from "react-cookie";

export default function AssetContent() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { filteredAsset } = useSelector(state => state.searchAsset);
    const { credential, partnerIds } = useSelector((state) => state.user);
    const { currentPage } = useSelector((state) => state.filterData.filterData["asset"]);
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    //assets table
    const perPage = 10;

    const handlePageChange = (selectedPage) => {
        dispatch(setCurrentPage({ category: "asset", currentPage: selectedPage.selected }));
    };

    const getCurrentData = () => {
        const startIndex = currentPage * perPage;
        const endIndex = startIndex + perPage;
        return filteredAsset.slice(startIndex, endIndex);
    };

    useEffect(() => {
        const fetchAssetData = async () => {
            const headers = {
                Authorization: `Bearer ${credential}`,
                PartnerId: getPartnerIds(partnerIds),
            };

            try {
                const assets = await getAssets(headers);

                dispatch(setAssetData(assets));
                setLoading(false);
            } catch (error) {
                console.error('API isteği sırasında bir hata oluştu:', error);
                setLoading(true);
            }
        };
        fetchAssetData();
    }, [credential, partnerIds, dispatch]);

    if (loading) {
        return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
    }

    const handleClick = (id) => {
        setCookie("selectedPartnerId", id, { path: "/" });
    }

    return (
        <div className="mx-auto max-w-7xl p-3 lg:px-8">
            {getCurrentData().length === 0
                ? <div className='flex items-center justify-center text-[#e2e2e2] text-2xl mt-4'>No asset data </div>
                : <>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4">
                        <div className="w-full text-sm text-left">
                            <div className='flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs'>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light'>
                                        Title
                                    </div>
                                </div>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light'>
                                        Version
                                    </div>
                                </div>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light'>
                                        Artist
                                    </div>
                                </div>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light'>
                                        Isrc
                                    </div>
                                </div>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light'>
                                        Products
                                    </div>
                                </div>
                            </div>
                            <div>
                                {getCurrentData().map((item, index) => (
                                    <div key={item.id} className='flex items-center h-[50px] py-3 p-6 text-primary font-light border-b border-[#E2E2E2] bg-white text-xs'
                                    onClick={() => handleClick(item.partner?.id)}
                                    >
                                        <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap font-light'>
                                                <Link to={item.id}>{item.title}</Link>
                                            </div>
                                        </div>
                                        <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap font-light'>
                                                <span>{item.trackVersion ? item.trackVersion : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap font-light'>
                                                <span>{item.displayArtists ? item.displayArtists : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap font-light'>
                                                <span>{item.isrc ? item.isrc : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap font-light'>
                                            <span>{item.products ? item.products : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <ReactPaginate
                        previousLabel={<RightIcon />}
                        nextLabel={<LeftIcon />}
                        pageCount={Math.ceil(filteredAsset.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'text-[#212121] text-[14px]'}
                        className='flex items-center justify-end  gap-4 py-6 text-gray-300 text-[14px]'
                    />
                </>
            }
        </div >
    )
}
