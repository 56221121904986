import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assetData: [],
}

const assetData = createSlice({
    name: 'assetData',
    initialState,
    reducers: {
        setAssetData: (state, action) => {
            state.assetData = action.payload;
        },
    },
});

export const { setAssetData } = assetData.actions;
export default assetData.reducer;
