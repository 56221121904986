import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredPeople: [],
}

const searchPeople = createSlice({
  name: 'searchPeople',
  initialState,
  reducers: {
    setFilteredPeople: (state, action) => {
      state.filteredPeople = [...action.payload];
    },
  },
});

export const { setFilteredPeople } = searchPeople.actions;
export default searchPeople.reducer;
