import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  credential: null,
  isAuthenticated: false,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      const { credential, isAuthenticated, isAdmin, partnerIds } =
        action.payload;
      Object.assign(state, {
        credential,
        isAuthenticated,
        isAdmin,
        partnerIds,
      });
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.credential = null;
    },
  },
});

export const { setLogin, logout } = user.actions;
export default user.reducer;
