import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const peopleIdentifierData = createSlice({
    name: 'peopleIdentifierData',
    initialState,
    reducers: {
        setPeopleIdentifierData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setPeopleIdentifierData } = peopleIdentifierData.actions;
export default peopleIdentifierData.reducer;
