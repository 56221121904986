import axios from "axios";
import {cleanHeaders} from "../clean-headers";

const getArtist = async (params, id) => {
    const headers = cleanHeaders(params);

    try {
        return await axios.get(
            `https://ddex-384918.ew.r.appspot.com/artist/${id}`,
            {headers}
        ).then((res) => res.data);
    } catch (error) {
        console.log(error);
    }
};

export { getArtist };
