import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    releaseData: [],
}

const releaseData = createSlice({
    name: 'releaseData',
    initialState,
    reducers: {
        setReleaseData: (state, action) => {
            state.releaseData = action.payload;
        },
    },
});

export const { setReleaseData } = releaseData.actions;
export default releaseData.reducer;
