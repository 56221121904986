import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  labelData: [],
}

const labelData = createSlice({
  name: 'labelData',
  initialState,
  reducers: {
    setLabelData: (state, action) => {
      state.labelData = action.payload;
    },
  },
});

export const { setLabelData } = labelData.actions;
export default labelData.reducer;
