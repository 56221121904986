import AppBar from "../components/AppBar";
import Header from "../components/Header";
import Navbar from "../components/common/Navbar";
import CreateProduct from "../components/products/CreateProduct";
import ReleaseContent from "../components/releases/ReleaseContent";
import { setFilteredRelease } from "../stores/searchRelease";
import {useSelector} from "react-redux";

export default function Releases() {
    const data = useSelector((state) => state.releaseData.releaseData);

    return (
        <>
            <Header />
            <AppBar />
            <Navbar
                category="release"
                searchLabel="Search for release"
                data={data}
                component={<CreateProduct />}
                filterAction={setFilteredRelease}
                hideCreateButton={true}
            />
            <ReleaseContent />
        </>
    );
}
