import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredPublisher: [],
}

const searchPublisher = createSlice({
  name: 'searchPublisher',
  initialState,
  reducers: {
    setFilteredPublisher: (state, action) => {
      state.filteredPublisher = [...action.payload];
    },
  },
});

export const { setFilteredPublisher } = searchPublisher.actions;
export default searchPublisher.reducer;
