import AppBar from "../components/AppBar";
import Navbar from "../components/common/Navbar";
import Header from "../components/Header";
import PeopleContent from "../components/people/PeopleContent";
import {useSelector} from "react-redux";
import CreatePeople from "../components/people/CreatePeople";
import {setFilteredPeople} from "../stores/searchPeople";

export default function Labels() {
  const data = useSelector((state) => state.peopleData.peopleData);

  return (
    <>
    <Header/>
    <AppBar/>
      <Navbar
          category="people"
          searchLabel="Search for people"
          data={data}
          component={<CreatePeople />}
          filterAction={setFilteredPeople}
          hideCreateButton={false}
      />
      <PeopleContent/>
    </>
  )
}
