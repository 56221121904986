import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    productData: [],
}

const productData = createSlice({
    name: 'productData',
    initialState,
    reducers: {
        setProductData: (state, action) => {
            state.productData = action.payload;
        },
    },
});

export const { setProductData } = productData.actions;
export default productData.reducer;
