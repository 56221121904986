import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { setProductData } from "../../stores/productData";
import { setCurrentPage } from "../../stores/filterData";
import { getPartnerIds } from "../../utils/lib/get-partners";
import { getProducts } from "../../utils/lib/get-products";
import Loading from "../Loading";
import { RightIcon, LeftIcon } from "../common/icons";
import {useCookies} from "react-cookie";

export default function ProductContent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { credential, partnerIds } = useSelector((state) => state.user);
  const { filteredProduct } = useSelector((state) => state.searchProduct);
  const { currentPage } = useSelector((state) => state.filterData.filterData["product"]);
  const [cookies, setCookie] = useCookies(["selectedPartnerId"]);


  //product table
  const perPage = 10;

  const handlePageChange = (selectedPage) => {
    dispatch(setCurrentPage({ category: "product", currentPage: selectedPage.selected }));
  };

  const getCurrentData = () => {
    const startIndex = currentPage * perPage;
    const endIndex = startIndex + perPage;

    return filteredProduct.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      const headers = {
        Authorization: `Bearer ${credential}`,
        PartnerId: getPartnerIds(partnerIds),
      };

      try {
        const products = await getProducts(headers);
        dispatch(setProductData(products));
        setLoading(false);
      } catch (error) {
        toast.error("API isteği sırasında bir hata oluştu:", error);
        setLoading(true);
      }
    };
    fetchProductData();
  }, [credential, partnerIds, dispatch]);

  if (loading) {
    return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
  }

  const handleProductClick = (id) => {
    if (!id) return;

    setCookie("selectedPartnerId", id, { path: "/" });
  }

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        {getCurrentData().length === 0 ? (
          <div className="flex items-center justify-center text-[#e2e2e2] text-2xl mt-4">
            No product data
          </div>
        ) : (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4">
              <div className="w-full text-sm text-left">
                <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Title
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Artist
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      BARCODE CAT. NR
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Release Version
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Date Added
                    </div>
                  </div>
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                      Release Date
                    </div>
                  </div>
                </div>
                <div>
                  {getCurrentData().map((item, index) => (
                    <div
                      key={item.id}
                      className="flex items-center h-[50px] py-3 p-6 text-primary font-light border-b border-[#E2E2E2] bg-white text-xs"
                      onClick={() => handleProductClick(item.partner?.id)}
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <Link to={item.id}>{item.title}</Link>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.displayArtists ? item.displayArtists : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.upc ? item.upc : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.releaseVersion ? item.releaseVersion : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.createdTime ? item.createdTime : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.releaseDate ? item.releaseDate : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <ReactPaginate
              previousLabel={<RightIcon />}
              nextLabel={<LeftIcon />}
              pageCount={Math.ceil(filteredProduct.length / perPage)}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"text-[#212121] text-[14px]"}
              className="flex items-center justify-end  gap-4 py-6 text-gray-300 text-[14px]"
            />
          </>
        )}
      </div>
    </>
  );
}
