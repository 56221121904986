import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const labelTranslationData = createSlice({
    name: 'labelTranslationData',
    initialState,
    reducers: {
        setLabelTranslationData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setLabelTranslationData } = labelTranslationData.actions;
export default labelTranslationData.reducer;
