import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Products from "./pages/Products";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NotFoundPage from "./pages/NotFound";
import Assets from "./pages/Assets";
import AssetDetail from "./components/assets/AssetDetail";
import Artists from "./pages/Artists";
import Labels from "./pages/Labels";
import People from "./pages/People";
import Publishers from "./pages/Publishers";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { logout, setLogin } from "./stores/auth";
import Loading from "./components/Loading";
import LabelDetail from "./components/labels/LabelDetail";
import PublisherDetail from "./components/publishers/PublisherDetail";
import PeopleDetail from "./components/people/PeopleDetail";
import "react-toastify/dist/ReactToastify.css";
import ArtistDetail from "./components/artists/ArtistDetail";
import ProductDetail from "./components/products/ProductDetail";
import Releases from "./pages/Releases";
import { getAllPartners } from "./utils/lib/get-partners";
import login from "./utils/lib/login";
import ReleaseDetail from "./components/releases/ReleaseDetail";

export default function App() {
  const { isAuthenticated, isAdmin } = useSelector(
    (state) => state.user
  );

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

  useEffect(() => {
    const checkAuthenticated = async () => {
      const accessToken = cookies.accessToken;

      if (accessToken) {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await login(headers);

        if (response.status === 401 || response.status === 500) {
          removeCookie("accessToken", { path: "/" });
          removeCookie("selectedPartnerId", { path: "/" });
          dispatch(logout());
          return;
        }

        const {isAdmin} = response

        let partnerIds;
        if (isAdmin) {
          partnerIds = await getAllPartners(headers);
        }

        dispatch(
          setLogin({
            isAuthenticated: true,
            credential: accessToken,
            isAdmin: isAdmin ?? null,
            partnerIds: partnerIds ?? null,
          })
        );

        setLoading(false);
      } else {
        dispatch(logout());
      }
    };
    setLoading(false);
    checkAuthenticated();
  }, [dispatch]);

  if (loading) {
    return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
  }

  return (
    <GoogleOAuthProvider clientId="228628598119-kdhss013ib9gb52vv8im380u7adkg9r2.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/catalog/products" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route exact path="/login" element={<Login />} />
          {isAuthenticated === true ? (
            <>
              <Route exact path="/catalog/products" element={<Products />} />
              <Route
                exact
                path="/catalog/products/:id"
                element={<ProductDetail />}
              />
              <Route exact path="/catalog/assets" element={<Assets />} />
              <Route
                exact
                path="/catalog/assets/:id"
                element={<AssetDetail />}
              />
              <Route exact path="/catalog/artists" element={<Artists />} />
              <Route
                exact
                path="/catalog/artists/:id"
                element={<ArtistDetail />}
              />
              <Route exact path="/catalog/labels" element={<Labels />} />
              <Route path="/catalog/labels/:id" element={<LabelDetail />} />
              <Route exact path="/catalog/people" element={<People />} />
              <Route
                exact
                path="/catalog/people/:id"
                element={<PeopleDetail />}
              />
              <Route
                exact
                path="/catalog/publishers"
                element={<Publishers />}
              />
              <Route
                path="/catalog/publishers/:id"
                element={<PublisherDetail />}
              />

              <Route
                exact
                path="/catalog/releases"
                element={
                  isAdmin ? (
                      <Releases />
                  ) : (
                    <Navigate to="/catalog/products" />
                  )
                }
              />
              <Route
                  exact
                  path="/catalog/releases/:id"
                  element={<ReleaseDetail />}
              />
            </>
          ) : (
            <Route path="/*" element={<NotFoundPage />} />
          )}
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}
