const cleanHeaders = (headers) => {
    return Object.keys(headers).reduce((acc, key) => {
        if (headers[key] !== null && headers[key] !== "") {
            acc[key] = headers[key];
        }

        return acc;
    }, {});
};

export { cleanHeaders };