import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredProduct: [],
};

const searchProduct = createSlice({
  name: "searchProduct",
  initialState,
  reducers: {
    setFilteredProduct: (state, action) => {
      state.filteredProduct = [...action.payload];
    },
  },
});

export const { setFilteredProduct } = searchProduct.actions;
export default searchProduct.reducer;
