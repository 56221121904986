import React, {Fragment, useState, useEffect, useRef, useCallback} from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';
import { useSpring, animated } from "react-spring";
import { logout } from '../../stores/auth';
import Loading from '../Loading'
import DeletePeople from './DeletePeople';
import { ChevronDownIcon, ChevronRightIcon, PencilIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { setOpenModal } from '../../stores/modal';
import { setPeopleTranslationData } from '../../stores/peopleTranslationData';
import { Dialog, Transition } from '@headlessui/react';
import PersonIdentifier from './PersonIdentifier';
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";
import {debounce} from "lodash";

const PeopleDetail = () => {
    const { id } = useParams();
    const [people, setPeople] = useState('');
    const { credential } = useSelector((state) => state.user);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [peopleName, setPeopleName] = useState(people.name);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [emptyValue, setEmptyValue] = useState(false);
    const [editTranslation, setEditTranslation] = useState(false);
    const [editedPeopleName, setEditedPeopleName] = useState();
    const [editingPeopleName, setEditingPeopleName] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [addTranslation, setAddTranslation] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [editAddedTranslation, setEditAddedTranslation] = useState(false);
    const [deletingTranslationId, setDeletingTranslationId] = useState('');
    const [editingTranslationId, setEditingTranslationId] = useState('');
    const [languageNames, setLanguageNames] = useState([]);
    const peopleTranslationData = useSelector((state) => state.peopleTranslationData);
    const { openModal } = useSelector(state => state.modal);
    const [filteredLanguage, setFilteredLanguage] = useState();
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    const headerSet = {
        Authorization: `Bearer ${credential}`,
        // PartnerId: getPartnerIds(partnerIds),
    };

    const closeModal = () => {
        dispatch(setOpenModal(false));
        setEditTranslation(false);
        setEditedPeopleName('');
    }

    const closeTranslationModal = () => {
        setAddTranslation(false);
        setSearchQuery('');
        setEditTranslation(false);
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
    };

    const handleSelectedLanguage = (language) => {
        setSelectedLanguage(language);
        dispatch(setOpenModal(true));
    };


  useEffect(() => {
      if (!peopleTranslationData) return;

      if (!Array.isArray(peopleTranslationData)) {
      console.error('peopleTranslationData dizi olmalı:', peopleTranslationData);
      return;
    }

    const addedLanguageNames = peopleTranslationData.map(translation => translation.language.toLowerCase());

    const searchFilteredLanguages = languageNames.filter(lang =>
      lang.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !addedLanguageNames.includes(lang.toLowerCase())
    );

        setFilteredLanguage(searchFilteredLanguages);
  }, [searchQuery, peopleTranslationData]);


    const labelProps = useSpring({
        fontSize: isFocused ? "12px" : "12px",
    });
    useEffect(() => {
        // publisher state'i değiştiğinde publisher Name'i güncelle
        setPeopleName(people.name);
    }, [people.name]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchPeople = async () => {
            const headers = {
                ...headerSet,
                partnerId: cookie.selectedPartnerId
            };
            try {
                if (people) {
                    const response = await axios.put(
                        `https://ddex-384918.ew.r.appspot.com/person/${id}`,
                        { ...people, name: peopleName },
                        { headers }
                    );
                    const updatePeopleData = response.data;
                    setPeople(updatePeopleData);
                } else {
                    const response = await axios.get(`https://ddex-384918.ew.r.appspot.com/person/${id}`, { headers });
                    const peoplesData = response.data;
                    setPeople(peoplesData);
                }
                setLoading(false);
            } catch (error) {
                console.error("API isteği sırasında bir hata oluştu:", error);
            }
        };

        fetchPeople();
    }, []);

    useEffect(() => {
        const fetchLang = async () => {
            const headers = {
                ...headerSet,
            };
            try {

                    const response = await axios.get(`https://ddex-384918.ew.r.appspot.com/language`, { headers });
                    const languages = response.data;
                    setLanguageNames(languages);

                setLoading(false);
            } catch (error) {
                console.error("API isteği sırasında bir hata oluştu:", error);
            }
        };

        fetchLang();
    }, [addTranslation]);

    const handleNameChange = (event) => {
        const { value } = event.target;
        if (value.trim() === '' && peopleName.trim() !== '') {
            setEmptyValue(true);
            return false;
        }
        setEmptyValue(false);
        setPeopleName(value);
        updatePeople(value);
    };

    const onSaveClick = () => {
        toast.success('People updated successfully');
    };

    const updatePeople = useCallback(
        debounce(
            async (value) => {
                setLoading(true);
                const headers = {
                    ...headerSet,
                    "Content-Type": "application/json",
                    partnerId: cookie.selectedPartnerId
                };
                try {
                    await axios.put(
                        `https://ddex-384918.ew.r.appspot.com/person/${id}`,
                        { ...people, name: value },
                        { headers }
                    );
                    setLoading(false);
                    toast.success('People updated successfully');
                } catch (error) {
                    console.error("API isteği sırasında bir hata oluştu:", error);
                }
            },
            1000
        ),
        []
    );

    const openEditTranslationModal = () => {
        setEditTranslation(true);
    }

    const handleChangeTranslation = (e) => {
        const { value } = e.target;
        setEditedPeopleName(value);
        setEditingPeopleName(value);
    }


    // get translation data
    useEffect(() => {
        const fetcPeopleTranslationData = async () => {
            const headers = {
                ...headerSet,
                partnerId: cookie.selectedPartnerId
            };
            try {
                const response = await axios.get(`https://ddex-384918.ew.r.appspot.com/person/${id}`, { headers });
                const translations = response.data.translations;
                dispatch(setPeopleTranslationData(translations));
                setLoading(false);

            } catch (error) {
                console.error('API isteği sırasında bir hata oluştu:', error);
                setLoading(true);
            }
        };
        fetcPeopleTranslationData();
    }, [dispatch]);

    //post translation data
    const createTranslationData = async () => {
        const headers = {
            ...headerSet,
            "Content-Type": "application/json",
            partnerId: cookie.selectedPartnerId
        };
        try {
            const response = await axios.post(`https://ddex-384918.ew.r.appspot.com/person/${id}/translation`, {
                language: selectedLanguage,
                personName: editedPeopleName
            }, {
                headers: headers
            });
            const createdTranslation = response.data;
            dispatch(setPeopleTranslationData(createdTranslation))
            dispatch(setOpenModal(false));
            closeModal();
            closeTranslationModal();
            window.location.reload();
        } catch (error) {
            console.error('API isteği sırasında bir hata oluştu:', error);
        }

    };

    //delete added translation data
    const deleteAddedTranslationData = async () => {
        const headers = {
            ...headerSet,
            "Content-Type": "application/json",
            partnerId: cookie.selectedPartnerId
        };
        try {
            const response = await axios.delete(`https://ddex-384918.ew.r.appspot.com/person/${id}/translation/${deletingTranslationId}`, {
                headers: headers
            });
            const deleteTranslationData = response.data;
            dispatch(setPeopleTranslationData(deleteTranslationData));
            setLoading(false);
            handleDeleteModal();
            window.location.reload();
        } catch (error) {
            console.error('API isteği sırasında bir hata oluştu:', error);
            setLoading(true);
        }
    };

    const handleDeleteModal = (translationId) => {
        setDeleteModal(!deleteModal);
        setDeletingTranslationId(translationId);
    }

    function selectPeopleNameForEditing(personName, translationId) {
        setEditAddedTranslation(!editAddedTranslation);
        setEditingPeopleName(personName);
        setEditingTranslationId(translationId);
    }

    //edited added translation data
    const editedAddedTranslationData = async () => {
        const headers = {
            ...headerSet,
            "Content-Type": "application/json",
            partnerId: cookie.selectedPartnerId
        };
        try {
            const response = await axios.put(`https://ddex-384918.ew.r.appspot.com/person/${id}/translation/${editingTranslationId}`, {
                personName: editingPeopleName
            }, {
                headers: headers
            });
            const updatedTranslationName = response.data;
            dispatch(setPeopleTranslationData(updatedTranslationName));
            setLoading(false);
            setEditAddedTranslation(false);
            window.location.reload();
        } catch (error) {
            console.error('API isteği sırasında bir hata oluştu:', error);
            setLoading(true);
        }
    };

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <ToastContainer autoClose={3000} />
            <Header />
            <DeletePeople />
            <div className="mx-auto max-w-7xl p-3 lg:px-8">
                <div className='flex items-center gap-2 py-6'>
                    <div>
                        <Link to='/catalog/products' className='text-[#555555] text-sm'>Catalog</Link>
                    </div>
                    <ChevronRightIcon className='w-4 h-4 text-[#555555]' />
                    <div>
                        <Link to='/catalog/people' className='text-[#555555] text-sm'>People</Link>
                    </div>
                    <ChevronRightIcon className='w-4 h-4 text-[#555555]' />
                    <div>
                        <Link to={`/catalog/people/${id}`} className='text-[#555555] text-sm'>{peopleName}</Link>
                    </div>

                    <div className="flex ml-auto">
                        <button
                            onClick={onSaveClick}
                            className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
                <div className='bg-white border border-[#e2e2e2] py-5 px-6'>
                    <div className='flex items-center justify-between'>
                        <div className='text-sm font-semibold'>{peopleName}</div>
                        <div className='text-xs font-light text-[#BBBBBB]'>ID: <span className='pl-2 text-[#212121]'>{people.id}</span></div>
                    </div>
                </div>
                <div className='mt-8 bg-white border border-[#e2e2e2] py-1 px-6'>
                    <div className='flex items-center justify-start'>
                        <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                            <div className="relative w-[350px]">
                                <animated.label
                                    className="inline-block bg-white font-normal text-gray-400"
                                    style={labelProps}
                                    htmlFor='peopleName'
                                >
                                    People name*
                                </animated.label>
                                <input
                                    type="text"
                                    name="peopleName"
                                    id="peopleName"
                                    defaultValue={peopleName}
                                    onChange={handleNameChange}
                                    ref={inputRef}
                                    className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2"
                                />
                                {emptyValue && <div className='text-red-400 text-xs mt-2'>Başlık alanı zorunludur. Lütfen bu alanı doldurun ve tekrar deneyin.</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-8 bg-white border border-[#e2e2e2]'>
                    <h2 className='text-[#616161] text-[13px] font-semibold leading-[1.2] py-3 px-6'>Translation</h2>
                    <div className='flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs'>
                        <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                            <span className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>Language</span>
                        </div>
                        <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                            <span className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>Publisher Name</span>
                        </div>
                        <div className='flex-[0_1_200px] mr-[10px] text-xs text-right relative'>
                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>
                                <div onClick={() => setAddTranslation(true)} className='flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer'>
                                    <PlusIcon className='w-5 h-5 text-primary' />
                                    <span className='text-right'>Add Translation</span>
                                    <ChevronDownIcon className='w-5 h-5- text-primary' />
                                </div>
                                {addTranslation &&
                                    <div className='z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]'>
                                        <div className='py-2 px-4'>
                                            <div className='flex flex-col'>
                                                <div className='mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]'>
                                                    <input type='text' onChange={handleSearch} placeholder='Type here to search for language' className='w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary' />
                                                </div>
                                                <hr className='-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]' />
                                                <ul className='max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none'>
                                                    {filteredLanguage?.map((language, index) => (
                                                        <div onClick={() => handleSelectedLanguage(language)} key={index} className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${index === 0 && 'bg-gray-200'}`}>
                                                            <span className='capitalize'>{language}</span>
                                                        </div>
                                                    ))}
                                                    {filteredLanguage?.length === 0 ?
                                                        <div className='h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize'>
                                                            Could not find anything
                                                        </div>
                                                        : null
                                                    }
                                                </ul>
                                                <hr className='-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]' />
                                                <div onClick={closeTranslationModal} className='flex flex-row-reverse pt-2'>
                                                    <button className='py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100'>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    {peopleTranslationData.length === 0 &&
                        <div className='h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center'>
                            No items available
                        </div>
                    }
                    {Array.isArray(peopleTranslationData) && peopleTranslationData.map((translation, index) => {
                        return (
                            <div key={translation.id} className={index % 2 === 0 ? 'flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs' : 'bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs'}>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <span className='inline-block max-w-full overflow-hidden whitespace-nowrap'>{translation.language}</span>
                                </div>
                                <div className='flex-[1_1_200px] text-xs text-left'>
                                    <span className='inline-block max-w-full overflow-hidden whitespace-nowrap'>{translation.personName}</span>
                                </div>
                                <div className='flex-[0_1_200px] mr-[20px] text-xs text-right relative'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>
                                        <div className='flex items-center gap-6'>
                                            <div onClick={() => selectPeopleNameForEditing(translation.personName, translation.id)} className='p-2 hover:bg-gray-100 rounded-full cursor-pointer'>
                                                <PencilIcon className='w-6 h-6 text-gray-500' />
                                            </div>
                                            <div onClick={() => handleDeleteModal(translation.id)} className='p-2 hover:bg-gray-100 rounded-full cursor-pointer'>
                                                <TrashIcon className='w-6 h-6 text-gray-500' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>

                <PersonIdentifier/>

                <div className="flex justify-end">
                    <button
                        onClick={onSaveClick}
                        className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    >
                        Save Changes
                    </button>
                </div>
            </div>
            {deleteModal && <>
                <Transition.Root show={deleteModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                        <div>
                                            <div className='text-lg font-normal px-4 pt-5'>
                                                <h1>Remove translation</h1>
                                                <p className='text-sm font-light py-4 text-[#757575] flex'>Are you sure you want to remove this translation from the Product?</p>
                                            </div>
                                        </div>
                                        <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                            <div>
                                            </div>
                                            <div className='flex items-center gap-4'>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                    onClick={handleDeleteModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                                    onClick={deleteAddedTranslationData}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>}

            {openModal &&
                <>
                    <Transition.Root show={openModal} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                            <div>
                                                <div className='text-lg font-normal px-4 pt-5'>
                                                    <h1>Add translations</h1>
                                                    <p className='text-sm font-light mt-2 text-[#757575] flex'>Insert translations by clicking the <PencilIcon className='w-6 h-6 px-1' />  button and saving it.</p>
                                                </div>
                                                <div className='relative w-ful'>
                                                    <div className='overflow-visible'>
                                                        <div className='flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4'>
                                                            <div className='flex-[1_1_200px] text-[10px] text-left px-4 pt-5'>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>Original</span>
                                                            </div>
                                                            <div className='flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5'>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>Translation</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='relative w-ful py-4'>
                                                    <div className='overflow-visible'>
                                                        <div className='flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white'>
                                                            <div className='flex-[1_1_200px] text-[12px] text-left px-4 '>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>{peopleName}</span>
                                                            </div>
                                                            <div className='flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 '>
                                                                {editTranslation
                                                                    ? <div className='flex items-center w-5/6 gap-4'>
                                                                        <input type='text' defaultValue={editedPeopleName} onChange={handleChangeTranslation} className='w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary' />
                                                                        <button onClick={createTranslationData} className='uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md'>Save</button>
                                                                    </div>
                                                                    : <span className='flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis'>{editedPeopleName}</span>
                                                                }
                                                                <div className='flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary'>
                                                                    <PencilSquareIcon onClick={openEditTranslationModal} className='text-black w-6 h-6' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                <div>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                        onClick={closeModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </>
            }

            {editAddedTranslation &&
                <>
                    <Transition.Root show={editAddedTranslation} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={selectPeopleNameForEditing}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                            <div>
                                                <div className='text-lg font-normal px-4 pt-5'>
                                                    <h1>Add translations</h1>
                                                    <p className='text-sm font-light mt-2 text-[#757575] flex'>Insert translations by clicking the <PencilIcon className='w-6 h-6 px-1' />  button and saving it.</p>
                                                </div>
                                                <div className='relative w-ful'>
                                                    <div className='overflow-visible'>
                                                        <div className='flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4'>
                                                            <div className='flex-[1_1_200px] text-[10px] text-left px-4 pt-5'>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>Original</span>
                                                            </div>
                                                            <div className='flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5'>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>Translation</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='relative w-ful py-4'>
                                                    <div className='overflow-visible'>
                                                        <div className='flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white'>
                                                            <div className='flex-[1_1_200px] text-[12px] text-left px-4 '>
                                                                <span className='inline-block max-w-full text-ellipsis whitespace-nowrap'>{peopleName}</span>
                                                            </div>
                                                            <div className='flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 '>
                                                                {editTranslation
                                                                    ? <div className='flex items-center w-5/6 gap-4'>
                                                                        <input type='text' defaultValue={editingPeopleName} onChange={handleChangeTranslation} className='w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary' />
                                                                        <button onClick={editedAddedTranslationData} className='uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md'>Save</button>
                                                                    </div>
                                                                    : <span className='flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis'>{editingPeopleName}</span>
                                                                }
                                                                <div className='flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary'>
                                                                    <PencilSquareIcon onClick={openEditTranslationModal} className='text-black w-6 h-6' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                                <div>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                        onClick={selectPeopleNameForEditing}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </>
            }
        </>
    );
};

export default PeopleDetail;
