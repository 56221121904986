import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import ReactPaginate from 'react-paginate';
import { setArtistData } from '../../stores/artistData';
import { UserIcon } from '@heroicons/react/24/outline';
import {setCurrentPage} from "../../stores/filterData";
import { getArtists } from "../../utils/lib/get-artists";
import {getPartnerIds} from "../../utils/lib/get-partners";
import { RightIcon, LeftIcon } from "../common/icons";
import {useCookies} from "react-cookie";

export default function ArtistContent() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { filteredArtist } = useSelector(state => state.searchArtist);
    const { credential, partnerIds } = useSelector((state) => state.user);
    const { currentPage } = useSelector((state) => state.filterData.filterData["artist"]);
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    //artists table
    const perPage = 10;

    const handlePageChange = (selectedPage) => {
        dispatch(setCurrentPage({ category: "artist", currentPage: selectedPage.selected }));
    };

    const getCurrentData = () => {
        const startIndex = currentPage * perPage;
        const endIndex = startIndex + perPage;
        return filteredArtist.slice(startIndex, endIndex);
    };

    useEffect(() => {
        const fetchArtistData = async () => {
            const headers = {
                Authorization: `Bearer ${credential}`,
                PartnerId: getPartnerIds(partnerIds),
            };

            try {
                const artists = await getArtists(headers);
                dispatch(setArtistData(artists));
                setLoading(false);
            } catch (error) {
                console.error('API isteği sırasında bir hata oluştu:', error);
                setLoading(true);
            }
        };
        fetchArtistData();
    }, [credential, partnerIds, dispatch]);

    if (loading) {
        return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
    }

    const handleClick = (id) => {
        setCookie("selectedPartnerId", id, { path: "/" });
    }
    return (
        <div className="mx-auto max-w-7xl p-3 lg:px-8">
            {getCurrentData().length === 0
                ? <div className='flex items-center justify-center text-[#e2e2e2] text-2xl mt-4'>No artist data </div>
                : <>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4 bg-white">
                        <div className="w-full text-sm text-left">
                            <div className='bg-white border-b border-[#E2E2E2]'>
                                <div>
                                    <div className="px-6 py-3 text-[10px] font-light text-[#212121] uppercase">
                                        Artist list
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-6'>
                                {getCurrentData().map((item, index) => (
                                    <div
                                        key={item.id}
                                        className='bg-white text-xs text-[#212121]'
                                        onClick={() => handleClick(item.partner?.id)}>
                                          <Link to={item.id}>
                                        <div className="p-6 whitespace-nowrap font-medium">
                                            {item.photoUrl ? (
                                                <img
                                                    className='w-36 h-36 object-cover rounded-full shadow-inner mb-4'
                                                    src={item.photoUrl}
                                                    alt={item.name}
                                                />
                                            ) : (
                                                <UserIcon className='w-36 h-36 bg-[#dcd6d6] text-[#b9b3b3] rounded-full p-8 shadow-inner mb-4'/>
                                            )}
                                            <span className='flex justify-center'>{item.name}</span>
                                        </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <ReactPaginate
                        previousLabel={<RightIcon />}
                        nextLabel={<LeftIcon />}
                        pageCount={Math.ceil(filteredArtist.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'text-[#212121] text-[14px]'}
                        className='flex items-center justify-end  gap-4 py-6 text-gray-300 text-[14px]'
                    />
                </>
            }
        </div >
    )
}
