import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const publisherTranslationData = createSlice({
    name: 'publisherTranslationData',
    initialState,
    reducers: {
        setPublisherTranslationData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setPublisherTranslationData } = publisherTranslationData.actions;
export default publisherTranslationData.reducer;
