import AppBar from "../components/AppBar";
import Navbar from "../components/common/Navbar";
import Header from "../components/Header";
import LabelContent from "../components/labels/LabelContent";
import CreateLabel from "../components/labels/CreateLabel";
import {setFilteredLabel} from "../stores/searchLabel";
import {useSelector} from "react-redux";

export default function Labels() {
  const data = useSelector((state) => state.labelData.labelData);

  return (
    <>
    <Header/>
    <AppBar/>
      <Navbar
          category="label"
          searchLabel="Search for label"
          data={data}
          component={<CreateLabel />}
          filterAction={setFilteredLabel}
          hideCreateButton={false}
      />
      <LabelContent/>
    </>
  )
}
