import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import { setLabelData } from '../../stores/labelData';
import ReactPaginate from 'react-paginate';
import {setCurrentPage} from "../../stores/filterData";
import {getPartnerIds} from "../../utils/lib/get-partners";
import {getLabels} from "../../utils/lib/get-labels";
import { RightIcon, LeftIcon } from "../common/icons";
import {useCookies} from "react-cookie";

export default function LabelContent() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { filteredLabel } = useSelector(state => state.searchLabel);

    const { credential, partnerIds } = useSelector((state) => state.user);
    const { currentPage } = useSelector((state) => state.filterData.filterData["label"]);
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    //label table
    const perPage = 10;

    const handlePageChange = (selectedPage) => {
        dispatch(setCurrentPage({ category: "label", currentPage: selectedPage.selected }));
    };

    const getCurrentData = () => {
        const startIndex = currentPage * perPage;
        const endIndex = startIndex + perPage;
        return filteredLabel.slice(startIndex, endIndex);
    };

    useEffect(() => {
        const fetchLabelData = async () => {
            const headers = {
                Authorization: `Bearer ${credential}`,
                PartnerId: getPartnerIds(partnerIds),
            };

            try {
                const labels = await getLabels(headers);

                dispatch(setLabelData(labels));
                setLoading(false);
            } catch (error) {
                console.error('API isteği sırasında bir hata oluştu:', error);
                setLoading(true);
            }
        };
        fetchLabelData();
    }, [credential, partnerIds, dispatch]);

    if (loading) {
        return <Loading />; // Oturum bilgileri yüklenene kadar kullanıcıyı bekletmek için loading durumu
    }

    const handleClick = (id) => {
        setCookie("selectedPartnerId", id, { path: "/" });
    }

    return (
        <div className="mx-auto max-w-7xl p-3 lg:px-8">
            {getCurrentData().length === 0
                ? <div className='flex items-center justify-center text-[#e2e2e2] text-2xl mt-4'>No label data </div>
                : <>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4">
                        <table className="w-full text-sm text-left">
                            <thead className='bg-white border-b border-[#E2E2E2]'>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-[10px] font-light text-[#212121] uppercase">
                                        Label name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {getCurrentData().map((item, index) => (
                                    <tr
                                        onClick={() => handleClick(item.partner?.id)}
                                        key={item.id} className={index % 2 === 0 ? 'bg-white text-xs text-primary' : 'bg-[#dc64780f] text-xs text-primary'}>
                                        <th scope="row" className="p-6 whitespace-nowrap font-light">
                                            <Link to={item.id}>{item.name}</Link>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        previousLabel={<RightIcon />}
                        nextLabel={<LeftIcon />}
                        pageCount={Math.ceil(filteredLabel.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'text-[#212121] text-[14px]'}
                        className='flex items-center justify-end  gap-4 py-6 text-gray-300 text-[14px]'
                    />
                </>
            }
        </div >
    )
}
