import { useEffect } from "react";
import { Popover } from "@headlessui/react";
import {
  CircleStackIcon,
  MagnifyingGlassIcon,
    ArrowsUpDownIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModal } from "../../stores/modal";
import {setQuery, setPartner, setCurrentPage, setSorting} from "../../stores/filterData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ data, category, searchLabel, component, filterAction, hideCreateButton }) => {
  const { query, partner, sorting } = useSelector((state) => state.filterData.filterData[category]);
  const { openModal } = useSelector((state) => state.modal);
  const { partnerIds, isAdmin } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(setOpenModal(!openModal));
  };
  const handleSearch = (event) => {
    const { value } = event.target;
    dispatch(setQuery({ category, query: value }));
  };

  useEffect(() => {
    let searchFilteredItems = data.filter((item) => {
        const filterItem = item.name || item.title || item.id;
          return filterItem.toLowerCase().includes(query?.toLowerCase())
        }
    );

    if (partner) {
      searchFilteredItems = searchFilteredItems.filter(
          (item) => item.partner.id === partner
      );
    }

    if (sorting) {
      if (sorting === "date_desc") {
        searchFilteredItems = searchFilteredItems.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
      } else if (sorting === "date_asc") {
        searchFilteredItems = searchFilteredItems.sort((a, b) => new Date(a.createdTime) - new Date(b.createdTime));
      } else if (sorting === "name_desc") {
        searchFilteredItems = searchFilteredItems.sort((a, b) => {
          const itemA = a.title ?? a.name;
          const itemB = b.title ?? b.name;
          return itemB.toLowerCase().localeCompare(itemA)
        })
      } else if (sorting === "name_asc") {
        searchFilteredItems = searchFilteredItems.sort((a, b) => {
          const itemA = a.title ?? a.name;
          const itemB = b.title ?? b.name;
          return itemA.toLowerCase().localeCompare(itemB)
        })
      }
    }

    dispatch(filterAction(searchFilteredItems));
  }, [query, partner, sorting, data, filterAction, dispatch]);

  useEffect(() => {
    dispatch(setCurrentPage({ category, currentPage: 0 }));

  }, [category, partner, dispatch]);

  const onSelectChange = (event) => {
    dispatch(setPartner({ category, partner: event.target.value }));
  }


  const onSelectSortingChange = (event) => {
    dispatch(setSorting({ category, sorting: event.target.value }));
  }

  const sortingOptions = [
    {value: 'date_desc', title: 'Id Descending (Z-A)'},
    {value: 'date_asc', title: 'Id Ascending (A-Z)'},
    {value: 'name_desc', title: 'Name Descending (Z-A)'},
    {value: 'name_asc', title: 'Name Ascending (A-Z)'},
  ];


  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white shadow-sm lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="relative flex justify-between lg:gap-8 xl:flex">
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex gap-4 items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                    <div className="w-1/2">
                      <label htmlFor="search" className="sr-only">
                        {searchLabel}
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm sm:leading-6"
                          placeholder={searchLabel}
                          type="search"
                          value={query}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    {isAdmin && (
                      <div className="w-1/2">
                        <label htmlFor="search" className="sr-only">
                          Select Partner
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <CircleStackIcon
                              className="h-4 w-4 text-gray-300"
                              aria-hidden="true"
                            />
                          </div>

                          <select
                            onChange={onSelectChange}
                            className="block w-full rounded-md bg-white py-2 pl-7 pr-10 border border-gray-300 text-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                          >
                            <option value="">Select Partner</option>
                            {partnerIds?.map((partner) => (
                              <option key={partner.id} value={partner.id}>
                                {partner.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!hideCreateButton && <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  <div
                    className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={handleModal}
                  >
                    <PlusIcon className="h-5 w-5 text-white" />
                    <span>Create</span>
                  </div>
                </div>}
              </div>
            </div>
          </>
        )}
      </Popover>
      {openModal && component}
      <div className="mx-auto max-w-7xl p-3 lg:px-8 pb-0">
        <div className="flex justify-end">
            <label htmlFor="search" className="sr-only">
              Select Partner
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <ArrowsUpDownIcon
                    className="h-4 w-4 text-gray-300"
                    aria-hidden="true"
                />
              </div>

              <select
                  onChange={onSelectSortingChange}
                  className="block w-full rounded-md bg-white py-2 pl-7 pr-10 border border-gray-300 text-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
              >
                {sortingOptions?.map((partner) => (
                    <option key={partner.value} value={partner.value}>
                      {partner.title}
                    </option>
                ))}
              </select>
            </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
