import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredLabel: [],
}

const searchLabel = createSlice({
  name: 'searchLabel',
  initialState,
  reducers: {
    setFilteredLabel: (state, action) => {
      state.filteredLabel = [...action.payload]; // action.payload'ı kopyalayarak filteredLabel'ı güncelle
    },
  },
});

export const { setFilteredLabel } = searchLabel.actions;
export default searchLabel.reducer;
