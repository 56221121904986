import AppBar from "../components/AppBar";
import Header from "../components/Header";
import Navbar from "../components/common/Navbar";
import CreateProduct from "../components/products/CreateProduct";
import ProductContent from "../components/products/ProductContent";
import { setFilteredProduct } from "../stores/searchProduct";
import {useSelector} from "react-redux";

export default function Products() {
    const data = useSelector((state) => state.productData.productData);

    return (
    <>
      <Header />
      <AppBar />
      <Navbar
          category="product"
          searchLabel="Search for products"
          data={data}
          component={<CreateProduct />}
          filterAction={setFilteredProduct}
      />
      <ProductContent />
    </>
  );
}
