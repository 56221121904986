import axios from "axios";
import {cleanHeaders} from "../clean-headers";

const getAllPartners = async (params) => {
  const headers = cleanHeaders(params);

  try {
    return await axios.get(
        "https://ddex-384918.ew.r.appspot.com/partner/GetAll",
        {headers}
    ).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const getPartnerIds = (data) => {
  if (!data) return "";

  return data.map((item) => `${item.id}`).join(", ");
};

export { getAllPartners, getPartnerIds };
