import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    publisherData: [],
}

const publisherData = createSlice({
    name: 'publisherData',
    initialState,
    reducers: {
        setPublisherData: (state, action) => {
            state.publisherData = action.payload;
        },
    },
});

export const { setPublisherData } = publisherData.actions;
export default publisherData.reducer;
